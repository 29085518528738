import { RESET_FILTERS, SET_FILTERS, FilterType } from "../types/filtersTypes";

const initialState: FilterType = null;

export default function filtersReducer(state = initialState, action: any): FilterType {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...action.payload,
      };
    case RESET_FILTERS:
      return initialState;
    default:
      return state;
  }
}
