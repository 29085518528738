import React from 'react';
import { Switch, Redirect } from "react-router-dom";
import { ProtectedRoute } from "./ProtectRoute";
import { PublicRoute } from './PublicRoute';
// Publics
import LogInViews from '../views/Auth/LogInViews';
import RecoveryPasswordViews from '../views/Auth/RecoveryPasswordViews';
import RestorePasswordSuccessViews from '../views/Auth/RestorePasswordSuccessViews';
import RestorePasswordViews from '../views/Auth/RestorePasswordViews';
// Protecteds
import ProfileView from '../views/Auth/ProfileView';
import UsersRouting from '../views/Dashboard/Users/UsersRouting';
import GsProductsRouting from '../views/Dashboard/Products/GsProductsRouting';
import BudgetsRouting from '../views/Dashboard/Budgets/BudgetsRouting';
import CommercialActionsRouting from '../views/Dashboard/CommercialActions/CommercialActionsRouting';
import EventsRouting from '../views/Dashboard/Events/EventsRouting';
import JobsRouting from '../views/Dashboard/Jobs/JobsRouting';
import TasksRouting from '../views/Dashboard/Tasks/TasksRouting';
import MenusRouting from '../views/Dashboard/Menus/MenusRouting';
import TaxesRouting from '../views/Dashboard/Taxes/TaxesRouting';
import { SCOPES } from '../utils/scopes';

export default function Routing() {
  return (
    <Switch>
      <PublicRoute exact path={['/', '/login']} component={LogInViews} />
      <PublicRoute exact path={['/restore']} component={RestorePasswordViews} />
      <PublicRoute exact path={['/restore/success']} component={RestorePasswordSuccessViews} />
      <PublicRoute exact path={['/recovery']} component={RecoveryPasswordViews} />
      <ProtectedRoute exact path={['/profile']} component={ProfileView} />
      <ProtectedRoute path={['/users']} component={UsersRouting} scopes={[SCOPES.USER_READ]}/>
      <ProtectedRoute path={['/gs-products']} component={GsProductsRouting} />
      <ProtectedRoute path={['/budgets']} component={BudgetsRouting} />
      <ProtectedRoute path={['/commercial-actions']} component={CommercialActionsRouting} />
      <ProtectedRoute path={['/events']} component={EventsRouting} />
      <ProtectedRoute path={['/jobs']} component={JobsRouting} />
      <ProtectedRoute path={['/tasks']} component={TasksRouting} />
      <ProtectedRoute path={['/menu']} component={MenusRouting} />
      <ProtectedRoute path={['/taxes']} component={TaxesRouting} />
      <Redirect to="/" />
    </Switch>
    // <ProtectedRoute exact path='/configuration' component={Configuration}/>
    );
}
