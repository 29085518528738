import React, { useEffect, useState } from 'react';
// Material UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Services
import { getJobs, getJobsFilters } from '../../services/JobsServices';
// Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { JobsFilters } from '../../redux/types/filtersTypes';
// App
import { FancyProgress } from '../../utils/FancyComponents';
import FancyList, { FancyItemText } from '../../utils/FancyList';
import FancyFilter, { FilterElement } from '../../utils/FancyFilter';
// Others
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) => createStyles({
  success: {
    color: theme.palette.success.main
  },
  warning: {
    color: theme.palette.warning.main
  },
  error: {
    color: theme.palette.error.main
  },
}));

const source: CancelTokenSource[] = new Array(1).fill(axios.CancelToken.source());

export default function JobsListComponent() {
  const classes = useStyles();
  const filters = useSelector((state: RootState) => state.filters);
  const [jobs, setJobs] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<any[]>([]);
  const [advisors, setAdvisors] = useState<any[]>([]);
  const [deparments, setDeparments] = useState<any[]>([]);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const STATUS: { [key: string]: { name: string, className: string } } = {
    P: { name: 'Pendiente', className: classes.warning },
    F: { name: 'Finalizados', className: classes.success },
    C: { name: 'Cancelados', className: classes.error },
  };
  const filterElements: FilterElement[] = [
    {
      type: 'DateRange',
      key: 'date',
      name: 'Fecha',
    },
    {
      type: 'Autocomplete',
      key: 'dpto',
      name: 'Departamento',
      autocompleteOptions: deparments.map(el => ({ value: el.IDX, name: el.DTO_DENO })),
      loading: loadingFilters,
    },
    {
      type: 'Autocomplete',
      key: 'resp',
      name: 'Responsable',
      autocompleteOptions: advisors.map(el => ({ value: el.IDX, name: el.VD_DENO })),
      loading: loadingFilters,
    },
    {
      type: 'Autocomplete',
      key: 'idev',
      name: 'Evento',
      autocompleteOptions: events.map(el => ({ value: el.IDX, name: el.EV_DENO })),
      loading: loadingFilters,
    },
  ];

  const fetchJobs = () => {
    setLoading(true);
    const params: any = { ...filters };
    if (filters && 'date' in filters && filters.date && filters.date[0]) {
      params['dfec'] = filters.date[0].format('DDMMYYYY');
      if (filters.date[1]) {
        params['hfec'] = filters.date[1].format('DDMMYYYY');
      }
    }
    delete params.date;
    source[0] = axios.CancelToken.source();
    getJobs(source[0], params)
      .then(result => {
        setJobs(result);
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setJobs([]);
          setLoading(false);
        }
      })
  };

  const fetchJobFilters = () => {
    setLoadingFilters(true);
    getJobsFilters(source[1])
      .then(result => {
        setEvents(result.eventos);
        setAdvisors(result.vendedores);
        setDeparments(result.departamentos);
        setLoadingFilters(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoadingFilters(false);
        }
      });
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    fetchJobFilters();
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters && filters.id === new JobsFilters().id) {
      fetchJobs();
    }
    return () => {
      source[0].cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const ExpandContent = ({ job }: { job: any }) => (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2"><strong>Responsable</strong></Typography>
        <Typography variant="body2">{job.Responsable}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {
        filters && <FancyFilter
          noSearch
          useTabs
          tabKey="estado"
          tabElements={[{ value: 'T', name: 'Todos' }].concat(Object.keys(STATUS).map(key => ({
            value: key,
            name: STATUS[key].name
          })))}
          elements={filterElements}
          defaultValues={new JobsFilters()}
        />
      }
      {
        !loading && <FancyList
          expand
          elements={jobs}
          count={jobs.length}
          idKey="IdPt"
          ListItemTextProps={(job: any) => ({
            primary: <FancyItemText textArray={[
              job.Descripcion,
              job.Evento || 'Sin evento',
            ]} />,
            secondary: <FancyItemText textArray={[
              `${job['Fecha Inicio']} ${job['Fecha cierre'] ? `- ${job['Fecha cierre']}` : ''}`,
              (job['Hora Inicio'] || job['Hora Fin']) && `${job['Hora Inicio']} ${job['Hora Fin'] ? `- ${job['Hora Fin']}` : ''}`,
              job.Departamento,
              STATUS[job.Estado] && <span className={STATUS[job.Estado].className}>
                {STATUS[job.Estado].name}
              </span>
            ]} />
          })}
          expandContent={(job: any) => <ExpandContent job={job} />}
        />
      }
      {loading && <FancyProgress color="primary" size={100} />}
    </React.Fragment >
  );
}
