import React, { useState } from 'react';
// Material ui
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';
// Forms
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";
// App
import { FancyTextInput, FancyButton, PasswordTextInput } from '../utils/FancyComponents';
import Logo from '../assets/media/tke.svg';
// Redux
import { useDispatch } from 'react-redux';
// Services
import { login } from '../services/AuthServices';
// Others
import { Link, useHistory } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: 60,
      width: 'auto'
    },
    span: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      }
    },
    paper: {
      width: '350px',
      height: '100%',
      padding: theme.spacing(2, 3),
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        padding: theme.spacing(1, 2),
      }
    }
  }),
);

const schema = yup.object().shape({
  username: yup.string().required().email(),  //wait for validation length
  password: yup.string().required(),  //wait for validation length
});

let source: CancelTokenSource = axios.CancelToken.source();

function LoginComponent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const { handleSubmit, control, errors } = methods;
  const [busy, setBusy] = useState(false);

  React.useEffect(() => {
    source = axios.CancelToken.source();
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: any) => {
    setBusy(true);
    login(data, history, dispatch, source)
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  }

  return (
    <Paper className={styles.paper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} container justify="center">
            <img src={Logo} alt="logo" className={styles.logo} />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h6">Iniciar Sesión</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={FancyTextInput}
              InputProps={{
                startAdornment: <InputAdornment position="start"><EmailIcon color="secondary" /></InputAdornment>,
              }}
              control={control}
              error={!!errors.username}
              helperText={errors.username && errors.username.message}
              defaultValue=""
              type="email"
              name="username"
              label="Correo"
              placeholder="Ingrese su correo"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={PasswordTextInput}
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              control={control}
              defaultValue=""
              autoComplete="current-password"
              name="password"
              label="Contraseña"
              placeholder="Ingrese su contraseña"
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  as={Checkbox}
                  name="remeberMe"
                  valueName="checked"
                  defaultValue={false}
                  control={control}
                />
              }
              label="Recordarme"
            />
          </Grid> */}
          <Grid item xs={12} container direction="row" justify="flex-end">
            <Typography component={Link} to='/restore' className={styles.span}>
              He olvidado mi contraseña
            </Typography>
          </Grid>
          <Grid item xs={12} container direction="row" justify="center">
            <FancyButton
              variant="contained"
              color='primary'
              type="submit"
              disabled={busy}
              loading={busy}
            >
              Iniciar
            </FancyButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export default LoginComponent;
