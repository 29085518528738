import axios, { CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import { showSuccessSnackBar } from '../redux/actions/ShowMessageActions';

export async function getBudgets(source: CancelTokenSource, params?: any) {
  return axios.get(`gs/budgets/`, { params: params, cancelToken: source.token })
    .then(response => response.data);
};

export async function getBudgetsPdf(source: CancelTokenSource, params?: any) {
  return axios.get(`gs/pdfbudgets/`, { params: params, responseType: 'blob', cancelToken: source.token })
    .then(response => {
      // Download file
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(url);
      // Download
      // const link = document.createElement('a');
      // link.href = url;
      // link.target = '_blank';
      // link.setAttribute('download', params.budget + '.pdf');
      // document.body.appendChild(link);
      // link.click();
      return response.data;
    });
};

export async function changeStatusBudget(data: any, dispatch: Dispatch<any>, source: CancelTokenSource) {
  return axios.post(`gs/statusbudgets/`, data, { cancelToken: source.token })
    .then(response => {
      dispatch(showSuccessSnackBar('Se modifico el estado del presupuesto'));
      return response.data;
    });
};