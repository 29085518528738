import axios, { CancelTokenSource } from 'axios';

export async function getCommercialActions(source: CancelTokenSource, params?: any) {
  return axios.get(`gs/ac/`, { params: params, cancelToken: source.token })
    .then(response => response.data);
};

export async function getCommercialActionsFilters(source: CancelTokenSource, params?: any) {
  return axios.get(`gs/filters/ac/`, { params: params, cancelToken: source.token })
    .then(response => response.data);
};
