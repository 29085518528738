import React from 'react';
// Material Ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Redux
import { dismissDialog } from '../../redux/actions/DialogActions';
import { useDispatch } from 'react-redux';
// Services
import { changeStatusBudget } from '../../services/BudgetServices';
// Forms
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
// App
import { FancyTextInput, FancyButton } from '../../utils/FancyComponents';
// Others
import axios, { CancelTokenSource } from 'axios';

interface ChangeStatusModalComponentProps {
  budgetId: number,
  type: 'approved' | 'denied' | '',
  fetchBudgets: () => void,
}

let source: CancelTokenSource = axios.CancelToken.source();

export default function ChangeStatusModalComponent(props: ChangeStatusModalComponentProps) {
  const dispatch = useDispatch();
  const schema = yup.object().shape({
    description: props.type === 'approved' ? yup.string() : yup.string().required(),
  });
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const { handleSubmit, control, errors } = methods;
  const [busy, setBusy] = React.useState(false);

  React.useEffect(() => {
    source = axios.CancelToken.source();
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: any) => {
    setBusy(true);
    const formData = {
      budget: props.budgetId,
      status: props.type === 'approved' ? 1 : 2,
      description: data.description ? data.description : undefined
    }
    changeStatusBudget(formData, dispatch, source)
      .then(response => {
        props.fetchBudgets();
        dispatch(dismissDialog());
        setBusy(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center" paragraph>
            <strong>Se {props.type === 'approved' ? 'Aprobará' : 'Denegará'} el Presupuesto</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={FancyTextInput}
            multiline
            rows={3}
            control={control}
            error={!!errors.description}
            helperText={errors.description && errors.description.message}
            defaultValue=""
            name="description"
            label="Descripción"
          />
        </Grid>
        <Grid item xs={12} container direction="row" justify="center">
          <Grid item>
            <FancyButton
              variant="contained"
              color='primary'
              type="submit"
              disabled={busy}
              loading={busy}
            >
              {props.type === 'approved' ? 'Aprobar' : 'Denegar'}
            </FancyButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
