import { SET_FILTERS, RESET_FILTERS, FilterType } from "../types/filtersTypes"

export const setFilters = (payload: Exclude<FilterType, null>) => {
  return {
    type: SET_FILTERS,
    payload: payload,
  }
}

export const resetFilters = () => {
  return {
    type: RESET_FILTERS,
  }
}