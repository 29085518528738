import { createMuiTheme } from '@material-ui/core/styles';

const ThemeConfig = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#126c87',
      // dark: will be calculated from palette.primary.main,
      contrastText: '#ffffff',
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: '#5B5B5B',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    h2: {
      textTransform: 'uppercase',
      fontSize: '1.6rem',
      fontWeight: 700,
      '@media (max-width: 959px)': {
        fontSize: '1.2rem',
      },
    },
    h3: {
      fontSize: '1.25rem',
      letterSpacing: '0.5px',
      fontWeight: 700,
      '@media (max-width: 959px)': {
        fontSize: '1.1rem',
      },
    },
    h5: {
      fontWeight: 500,
      letterSpacing: '0.75px',
      '@media (max-width: 959px)': {
        fontSize: '1rem',
      },
    }
  },
});

export const Theme = createMuiTheme({
  ...ThemeConfig,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '::-webkit-scrollbar': {
          width: ThemeConfig.spacing(1),
          backgroundColor: ThemeConfig.palette.primary.contrastText,
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: ThemeConfig.palette.primary.main,
        },
        '::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
          backgroundColor: ThemeConfig.palette.primary.contrastText,
        },
      },
    },
    MuiPickersDesktopDateRangeCalendar: {
      rangeCalendarContainer: {
        borderRight: 'none !important'
      },
      arrowSwitcher: {
        backgroundColor: ThemeConfig.palette.primary.main,
        color: ThemeConfig.palette.primary.contrastText
      }
    },
    MuiPickersCalendarHeader: {
      root: {
        backgroundColor: ThemeConfig.palette.primary.main,
        color: ThemeConfig.palette.primary.contrastText,
        marginTop: 0,
        marginBottom: 0,
        paddingTop: ThemeConfig.spacing(2),
        paddingBottom: ThemeConfig.spacing(1),
      }
    },
    // MuiPickersArrowSwitcher: {
    //   root: {
    //     backgroundColor: ThemeConfig.palette.primary.main,
    //     color: ThemeConfig.palette.primary.contrastText
    //   },
    // },
    MuiPickersCalendar: {
      daysHeader: {
        backgroundColor: ThemeConfig.palette.primary.main,
      },
      weekDayLabel: {
        color: ThemeConfig.palette.primary.contrastText
      }
    },
    MuiPickersToolbar: {
      dateTitleContainer: {
        justifyContent: 'space-between',
      }
    },
    MuiPickersToolbarText: {
      root: {
        fontSize: ThemeConfig.typography.h4.fontSize
      },
    }
  },
});
