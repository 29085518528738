//v1.4
import React, { useState, ReactElement } from 'react';
// Material UI
import Grid, { GridProps } from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { DateRange } from '@material-ui/pickers';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../redux/store';
import { FilterType } from '../redux/types/filtersTypes';
import { setFilters } from '../redux/actions/FiltersActions';
// Forms
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
// App
import { FancyTextInput, FancyButton, FancyTabs, FancyTab, useFancyStyles } from './FancyComponents';
import DateRangePickerPopover from './DateRangePickerPopover';
import VirtualizedAutocompleteComponent from './VirtualizedAutocompleteComponent';
// Others
import { Moment } from 'moment';

type KeysOfUnion<T> = T extends any ? keyof T : never;

const isFilterType = (key: KeysOfUnion<Exclude<FilterType, null>>, filters: FilterType): key is keyof Exclude<FilterType, null> => {
  if (filters && key in filters) {
    return true;
  } else {
    return false;
  }
}

export const getFilterValue = (key: KeysOfUnion<Exclude<FilterType, null>>, filters: FilterType): any => {
  if (filters && isFilterType(key, filters)) {
    return filters[key];
  }
  return '';
}

export interface ArrayElement {
  value: string | number,
  name: string,
}

export type FilterElement = (FilterElementAutocomplete | FilterElementAutocompleteMultiple | FilterElementDateRange | FilterElementSelect | FilterElementCheckbox) & {
  key: KeysOfUnion<Exclude<FilterType, null>>;
  name: string;
  gridProps?: GridProps;
  hide?: boolean;
};

interface FilterElementAutocomplete {
  type: 'Autocomplete';
  autocompleteOptions: string[] | ArrayElement[];
  loading?: boolean;
}

interface FilterElementAutocompleteMultiple {
  type: 'AutocompleteMultiple';
  autocompleteOptions: ArrayElement[];
  loading?: boolean;
}

interface FilterElementDateRange {
  type: 'DateRange';
};

interface FilterElementSelect {
  type: 'Select';
  selectItems: { value: string, name: string }[];
};

interface FilterElementCheckbox {
  type: 'Checkbox';
  label: string;
};

interface Tabs {
  useTabs: true;
  tabKey: KeysOfUnion<Exclude<FilterType, null>>;
  tabElements: { value: string, name: string }[];
  onTabChange?: (event: React.ChangeEvent<{}>) => void;
};

interface FilterProps {
  defaultValues: Exclude<FilterType, null>;
  elements?: FilterElement[];
  busy?: boolean;
  noSearch?: boolean;
  beforeSearchContent?: ReactElement;
  schema?: yup.ObjectSchema<object | undefined>;
  onReset?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSubmit?: () => void;
};

type FancyFilterProps = FilterProps & (Tabs | { useTabs?: false })

export default function FancyFilter(props: FancyFilterProps) {
  const classes = useFancyStyles();
  const dispatch = useDispatch();
  const filters = useSelector((state: RootState) => state.filters);
  const [openedFilters, setOpenedFilters] = useState(false);
  const methods = useForm({
    resolver: yupResolver(props.schema || yup.object()),
  });
  const { handleSubmit, control, errors, reset } = methods;

  React.useEffect(() => {
    if (filters) {
      const newFilters: FilterType = { ...filters };
      if (props.useTabs && newFilters && isFilterType(props.tabKey, newFilters)) {
        newFilters[props.tabKey] = getTabDefaultValue();
      }
      reset(newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onSubmit = (data: any) => {
    data.id = filters?.id;
    if (props.elements) {
      const autocompleteElements = props.elements.filter(el => el.type === 'Autocomplete');
      if (autocompleteElements.length > 0) {
        autocompleteElements.forEach(el => {
          if (data[el.key]) {
            data[el.key] = typeof data[el.key] === 'object' ? data[el.key].value : data[el.key];
          }
        })
      }
    }
    dispatch(setFilters(data));
    if (props.onSubmit) {
      props.onSubmit();
    }
  }

  const getTabDefaultValue = () => {
    if (props.useTabs) {
      const value = props.tabElements.find(el => el.value === getFilterValue(props.tabKey, filters));
      if (value) {
        return value.value;
      }
      if (props.defaultValues) {
        return getFilterValue(props.tabKey, props.defaultValues) || null;
      }
    }
    return null;
  };

  const handleReset = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props.defaultValues) {
      reset(props.defaultValues);
      dispatch(setFilters(props.defaultValues));
      if (props.onReset) {
        props.onReset(event);
      }
    }
  }

  const handleTabChange = (onChange: any) => (event: React.ChangeEvent<{}>, newValue: any) => {
    if (filters && props.useTabs) {
      onChange(newValue);
      handleSubmit(onSubmit)();
      if (props.onTabChange) {
        props.onTabChange(event);
      }
    }
  };

  const handleAutocompleteOptionSelected = (option: any, selected: any): boolean => {
    const optionValue = typeof option === 'object' ? option.value : option;
    const selectedValue = typeof selected === 'object' ? selected.value : selected;
    return optionValue === selectedValue;
  };

  const handleAutocompleteOptionLabel = (options: (string | ArrayElement)[]) =>
    (option: any): string => {
      switch (typeof options[0]) {
        case 'string':
          return option;
        case 'object':
          if (typeof option === 'object') {
            return option.name;
          } else {
            const selected: any = options.find((op: any) => 'value' in op && op.value === option);
            if (selected && 'name' in selected) {
              return selected.name;
            } else {
              return option;
            }
          }
        default:
          return '';
      }
    };

  const handleAutocompleteMultipleChange = (onChange: any, key: KeysOfUnion<Exclude<FilterType, null>>,
    values: ArrayElement[]) => (event: any, newValue: any | null, reason: any) => {
      if (newValue && !values.find(val => val.value === newValue.value)) {
        onChange(values.concat(newValue));
      }
    };

  const handleAutocompleteMultipleChipDelete = (onChange: any, key: KeysOfUnion<Exclude<FilterType, null>>,
    values: ArrayElement[],
    elToDelete: ArrayElement) => () => {
      onChange(values.filter(el => el.value !== elToDelete.value));
    };

  const getComponent = (element: FilterElement) => (props: ControllerRenderProps) => {
    switch (element.type) {
      case 'Autocomplete':
        return (
          <VirtualizedAutocompleteComponent
            value={props.value}
            onChange={(event: any, newValue: any) => props.onChange(newValue)}
            options={element.autocompleteOptions || []}
            loading={element.loading}
            getOptionSelected={handleAutocompleteOptionSelected}
            getOptionLabel={handleAutocompleteOptionLabel(element.autocompleteOptions || [])}
            renderOption={(option: any) => <Typography noWrap>{handleAutocompleteOptionLabel(element.autocompleteOptions || [])(option)}</Typography>}
            renderInput={(params: any) => <FancyTextInput
              {...params}
              placeholder="Todas"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {element.loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />}
          />
        );
      case 'AutocompleteMultiple':
        return (
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <VirtualizedAutocompleteComponent
                onChange={handleAutocompleteMultipleChange(props.onChange, element.key, props.value)}
                options={element.autocompleteOptions || []}
                loading={element.loading}
                getOptionLabel={(option: any) => option.name}
                renderOption={(option: any) => <Typography noWrap>{option.name}</Typography>}
                renderInput={(params: any) => <FancyTextInput
                  {...params}
                  placeholder="Todas"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {element.loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              {
                props.value && props.value.map((el: ArrayElement) => (
                  <Chip
                    key={el.value}
                    label={el.name}
                    onDelete={handleAutocompleteMultipleChipDelete(props.onChange, element.key, props.value, el)}
                    className={classes.m_05}
                  />
                ))
              }
            </Grid>
          </Grid>
        );
      case 'DateRange':
        return (
          <DateRangePickerPopover
            initialValue={props.value}
            onSubmit={(date: DateRange<Moment>) => props.onChange(date)}
          />
        );
      case 'Select':
        return (
          <FancyTextInput {...props} select fullWidth SelectProps={{ displayEmpty: true }}>
            {(element.selectItems || []).map(item => (
              <MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>
            ))}
          </FancyTextInput>
        );
      case 'Checkbox':
        return (
          <FormControlLabel
            control={<Checkbox
              {...props}
              color="primary"
              onChange={e => props.onChange(e.target.checked)}
              checked={props.value}
            />}
            label={element.label}
          />
        );
    }
  }

  return (
    // check if redux filters and props filters are the same interface (every interface must have an unique id)
    (filters && filters.id === props.defaultValues.id) ? <form onSubmit={handleSubmit(onSubmit)} className={classes.mb_1}>
      <Grid container alignItems="center" spacing={1}>
        {
          props.useTabs && <Grid item xs={12}>
            <Controller
              render={renderProps => <FancyTabs
                value={renderProps.value}
                onChange={handleTabChange(renderProps.onChange)}
                className={classes.mb_1}
                variant="scrollable"
                scrollButtons="auto"
              >
                {props.tabElements.map(tab => <FancyTab key={tab.value} value={tab.value} label={tab.name} />)}
              </FancyTabs>}
              name={props.tabKey}
              defaultValue={getTabDefaultValue()}
              control={control}
            />
          </Grid>
        }
        <Grid item xs={12}>
          <Grid
            container
            justify={props.beforeSearchContent ? 'space-between' : 'flex-end'}
            alignItems="center"
            spacing={1}
          >
            {
              props.beforeSearchContent && <Grid item>
                {props.beforeSearchContent}
              </Grid>
            }
            {
              !props.noSearch && <Grid item xs={props.elements && props.elements.length > 0 ? 10 : 12} sm={6}>
                <Controller
                  as={FancyTextInput}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="search submit"
                          color="secondary"
                          type="submit"
                          disabled={props.busy}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                  }}
                  control={control}
                  error={!!errors.search}
                  helperText={errors.search && errors.search.message}
                  defaultValue={getFilterValue('search', filters)}
                  name="search"
                  type="search"
                  label="Buscar"
                />
              </Grid>
            }
            {
              props.elements && props.elements.length > 0 && <Grid item xs={2} sm="auto">
                <Hidden xsDown>
                  <FancyButton
                    variant="contained"
                    color={openedFilters ? 'primary' : 'secondary'}
                    startIcon={<TuneIcon />}
                    disabled={props.busy}
                    type="button"
                    onClick={() => setOpenedFilters(prev => !prev)}
                  >
                    Filtros
                  </FancyButton>
                </Hidden>
                <Hidden smUp>
                  <Fab
                    color={openedFilters ? 'primary' : 'secondary'}
                    onClick={() => setOpenedFilters(prev => !prev)}
                    disabled={props.busy}
                    size="small"
                    type="button"
                  >
                    <TuneIcon />
                  </Fab>
                </Hidden>
              </Grid>
            }
            {
              props.elements && props.elements.length > 0 && <Grid item xs={12} style={{ padding: 0 }}>
                <Collapse in={openedFilters} timeout="auto">
                  <Paper className={classes.p_1}>
                    <Grid container spacing={1}>
                      {
                        props.elements.filter(element => !element.hide).map((element, index) => {
                          const error = errors[element.key];
                          const md = (element.type === 'AutocompleteMultiple') ? 12 : 4;
                          const lg = (element.type === 'AutocompleteMultiple') ? 12 : 3;
                          return (
                            <Grid item xs={12} md={md} lg={lg} {...element.gridProps} key={index}>
                              <Grid container justify="center">
                                <Grid item xs={12}>
                                  <Typography>{element.name}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Controller
                                    render={getComponent(element)}
                                    control={control}
                                    name={element.key}
                                    defaultValue={getFilterValue(element.key, filters)}
                                    error={!!error}
                                    helperText={error && error.message}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        })
                      }
                      <Grid item xs={12} container justify="flex-end" spacing={1}>
                        {
                          props.defaultValues && <Grid item>
                            <FancyButton
                              variant="text"
                              disabled={props.busy}
                              type="button"
                              onClick={handleReset}
                            >
                              Limpiar Filtros
                          </FancyButton>
                          </Grid>
                        }
                        <Grid item>
                          <FancyButton
                            variant="text"
                            color="primary"
                            type="submit"
                            disabled={props.busy}
                          >
                            Filtrar
                        </FancyButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Collapse>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    </form> : null
  )
}