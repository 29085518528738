import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
//Material
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import AssessmentIcon from '@material-ui/icons/Assessment';
// import RestaurantRoundedIcon from '@material-ui/icons/RestaurantRounded';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WorkIcon from '@material-ui/icons/Work';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
//Redux
import { RootState } from "../redux/store";
import { AuthUser } from '../redux/types/authTypes';
import { useSelector } from "react-redux";
//App
import { Link, LinkProps, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.contrastText,
      width: '240px',
    },
    selectedList: {
      backgroundColor: 'rgba(0, 0, 0, 0.2) !important'
    }
  }),
);

interface MenuItem {
  name: string
  icon: any
  to: string | null
  expand: boolean
  children: { name: string, to: string | null }[]
  permissions: string[]
}

const MENUITEMS: MenuItem[] = [
  {
    name: 'Usuarios',
    icon: <GroupRoundedIcon />,
    to: '/users/list',
    expand: false,
    children: [],
    permissions: ['is_superuser']
  },
  {
    name: 'Eventos',
    icon: <EventRoundedIcon />,
    to: '/events/list',
    expand: false,
    children: [],
    permissions: ['is_user']
  },
  // {
  //   name: 'Productos',
  //   icon: <RestaurantRoundedIcon />,
  //   to: '/gs-products/list',
  //   expand: false,
  //   children: [],
  //   permissions: ['is_user']
  // },
  // {
  //   name: 'Impuestos',
  //   icon: <AttachMoneyIcon />,
  //   to: '/taxes/list',
  //   expand: false,
  //   children: [],
  //   permissions: ['is_user']
  // },
  {
    name: 'Presupuestos',
    icon: <DescriptionIcon />,
    to: '/budgets/list',
    expand: false,
    children: [],
    permissions: ['is_user']
  },
  {
    name: 'Acciones Comerciales',
    icon: <AccountBalanceIcon />,
    to: '/commercial-actions/list',
    expand: false,
    children: [],
    permissions: ['is_user']
  },
  {
    name: 'Tareas',
    icon: <AssignmentIcon />,
    to: '/tasks/list',
    expand: false,
    children: [],
    permissions: ['is_user']
  },
  {
    name: 'Partes de Trabajo',
    icon: <WorkIcon />,
    to: '/jobs/list',
    expand: false,
    children: [],
    permissions: ['is_user']
  },
  {
    name: 'Pruebas de Menu',
    icon: <AssessmentIcon />,
    to: '/menu/list',
    expand: false,
    children: [],
    permissions: ['is_user']
  },
];

function ListItemLink(props: any) {
  const { to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <ListItem {...props} component={to ? renderLink : 'button'} />
  );
};

export function SidebarNavigation() {
  const classes = useStyles();
  const history = useHistory();
  const user: AuthUser = useSelector((state: RootState) => state.auth.user);
  const getMenuItems = () => {
    let menuItems = [...MENUITEMS];
    if (!user.is_superuser) {
      menuItems = menuItems.filter(item => !item.permissions.includes('is_superuser'));
    }
    return menuItems;
  }
  const menuItems = getMenuItems();
  const [expanded, setExpanded] = React.useState<boolean[]>(new Array(menuItems.length).fill(false));

  useEffect(() => {
    const selectedMenuItemIndex = menuItems.findIndex(item => !!item.children.find(child => child.to === history.location.pathname));
    if (selectedMenuItemIndex > -1) {
      setExpanded(expanded.map((el, i) => {
        if (i === selectedMenuItemIndex) {
          return !el;
        }
        return el;
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (index: number, item: any) => {
    if (item.expand) {
      setExpanded(expanded.map((o, i) => {
        if (i === index) {
          return !o;
        }
        return o;
      }));
    }
  };

  const handleSelected = (item: any) => {
    return item.to === history.location.pathname;
  }

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <List>
          {
            menuItems.map((item: any, index) => (
              <React.Fragment key={item.name}>
                <ListItemLink
                  button
                  onClick={() => handleClick(index, item)}
                  selected={handleSelected(item)}
                  to={item.to}
                  classes={{
                    selected: classes.selectedList
                  }}
                >
                  <ListItemIcon style={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                  {item.expand ? expanded[index] ? <ExpandLess /> : <ExpandMore /> : null}
                </ListItemLink>
                {
                  item.expand && <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                      {
                        item.children.map((child: any) => (
                          <ListItemLink
                            button
                            key={child.name}
                            to={child.to}
                            selected={child.to === history.location.pathname}
                            classes={{
                              selected: classes.selectedList
                            }}
                          >
                            <ListItemIcon style={{ color: 'inherit' }} />
                            <ListItemText primary={child.name} />
                          </ListItemLink>
                        ))
                      }
                    </List>
                  </Collapse>
                }
              </React.Fragment>
            ))
          }
        </List>
      </Grid>
    </Grid>
  )
}