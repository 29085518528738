export const SET_REQUEST_EVENT_DATA = 'SET_REQUEST_EVENT_DATA';
export const CLEAR_REQUEST_EVENT_DATA = 'CLEAR_REQUEST_EVENT_DATA';

export class RequestEvent {
  id: number = 0;
  total: number = 0;
  subtotal: number = 0;
  iva_amount: number = 0;
  created_at: string = '';
  status: 'draft' | 'review' | 'approved' | 'confirmed' | 'delivered' = 'draft';
  guests: number = 0;
  street?: string;
  number?: string;
  reference?: string;
  contact_number?: string;
  datetime_event_start: string = '';
  datetime_event_end: string = '';
  time_before: string = '';
  oven?: boolean;
  vitroceramic?: boolean;
  fryer?: boolean;
  crockery?: boolean;
  coupon_code?: string;
  province: number = 0;
  province__name: string = '';
  municipality: number = 0;
  municipality__name: string = '';
  client: Client = new Client();
  provider_details: EventProvider[] = [];
}

// TODO: Add generic client model
export class Client {
  id: number = 0;
  first_name: string = '';
  last_name: string = '';
  email: string = '';
  phone?: string;
};

export interface EventProvider {
  id: number;
  total: string;
  subtotal: string;
  iva_amount: string;
  products_total?: number;
  products_subtotal?: number;
  products_iva_amount?: number;
  packs_total?: number;
  packs_subtotal?: number;
  packs_iva_amount?: number;
  provider_id: number;
  provider__name: string;
  provider__avatar: string;
  provider__type: number;
  provider__type__name: string;
  provider_product_details: EventProduct[];
  provider_pack_details: EventPack[]
};

export interface EventProduct {
  id: number;
  idx: string;
  name: string;
  price: string;
  iva_percentage: string;
  cant: string;
  total: string;
  iva_amount: string;
  subtotal: string;
  product: number;
  provider_request_event_detail: number;
  request_event?: number;
  images: { id: number, image: string }[];
}

export interface Product {
  id: number;
  idx: string;
  name: string;
  description: string;
  group: string;
  youtube_video: string | null;
  use_type: string;
  people_use_quantity: string | null
  company__name: string
  home_market_enabled: boolean;
  home_market_category: number
  home_market_category__name: string
  home_market_labels: { id: number, name: string, image: string }[];
  home_market_price: string;
  home_market_iva: number;
  home_market_iva__percentage: number
  home_market_iva_amount: number
  home_market_subtotal_amount: number
  images: { id: number, image: string }[];
}

export interface EventPack {
  id: number;
  cant: number;
  total: number;
  iva_amount: number;
  subtotal: number;
  name: string;
  price: number;
  iva_percentage: number;
  provider_request_event_detail: number;
  request_event: number;
  pack_id: number;
  groups: EventGroup[];
  images: { id: number, image: string }[];
}

export interface EventGroup {
  id: number;
  provider_request_event_pack: number;
  pack_group_id: number;
  pack_group_copy_id: number;
  pack_group__name: string;
  pack_group__selection_type: 'unique' | 'complete';
  products: EventGroupProduct[];
  selected_product: EventGroupProduct;
}

export interface EventGroupProduct {
  id: number;
  idx: string;
  product: number;
  name: string;
  price: string;
  iva_percentage: string;
  images: { id: number, image: string }[];
}

export interface Pack {
  id: number;
  name: string;
  price: string;
  iva__percentage: number;
  iva_amount: number;
  subtotal_amount: number;
  images: { id: number, image: string }[];
  groups: Group[];
}

export interface Group {
  id: number;
  name: string;
  selection_type: 'unique' | 'complete';
  pack: number;
  products: Product[]
}