import React, { useEffect, useState } from 'react';
// Material UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Services
import { getTasks, getTaskFilters } from '../../services/TasksServices';
// Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { TaskFilters } from '../../redux/types/filtersTypes';
// App
import { FancyProgress } from '../../utils/FancyComponents';
import FancyList, { FancyItemText } from '../../utils/FancyList';
import FancyFilter, { FilterElement } from '../../utils/FancyFilter';
// Others
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) => createStyles({
  success: {
    color: theme.palette.success.main
  },
  warning: {
    color: theme.palette.warning.main
  },
}));

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function TasksListComponent() {
  const classes = useStyles();
  const filters = useSelector((state: RootState) => state.filters);
  const [tasks, setTasks] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState<any[]>([]);
  const [advisors, setAdvisors] = useState<any[]>([]);
  const [events, setEvents] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const STATUS: { [key: string]: { name: string, className: string } } = {
    P: { name: 'Pendiente', className: classes.warning },
    R: { name: 'Resuelto', className: classes.success },
  };
  const filterElements: FilterElement[] = [
    {
      type: 'DateRange',
      key: 'date',
      name: 'Fecha',
    },
    {
      type: 'Autocomplete',
      key: 'dpto',
      name: 'Departamento',
      autocompleteOptions: departments.map(el => ({ value: el.IDX, name: el.DTO_DENO })),
      loading: loadingFilters,
    },
    {
      type: 'Autocomplete',
      key: 'pasig',
      name: 'Responsable',
      autocompleteOptions: advisors.map(el => ({ value: el.IDX, name: el.VD_DENO })),
      loading: loadingFilters,
    },
    {
      type: 'Autocomplete',
      key: 'idev',
      name: 'Evento',
      autocompleteOptions: events.map(el => ({ value: el.IDX, name: el.EV_DENO })),
      loading: loadingFilters,
    },
    {
      type: 'Autocomplete',
      key: 'loc',
      name: 'Localización',
      autocompleteOptions: locations.map(el => ({ value: el.IDX, name: el.LZ_DENO })),
      loading: loadingFilters,
    },
    {
      type: 'Autocomplete',
      key: 'resp',
      name: 'Asesor',
      autocompleteOptions: advisors.map(el => ({ value: el.IDX, name: el.VD_DENO })),
      loading: loadingFilters,
    },
  ];

  const fetchTasks = () => {
    setLoading(true);
    const params: any = { ...filters };
    if (filters && 'date' in filters && filters.date && filters.date[0]) {
      params['dfec'] = filters.date[0].format('DDMMYYYY');
      if (filters.date[1]) {
        params['hfec'] = filters.date[1].format('DDMMYYYY');
      }
    }
    delete params.date;
    source[0] = axios.CancelToken.source();
    getTasks(source[0], params)
      .then(result => {
        const tasks: any[] = [];
        result.forEach((task: any) => {
          // if there is Assigned Personel repeat element as many times as 'P_Rel'
          if (task.P_Rel && task.P_Rel.length > 0) {
            let p_rel: string[] = task.P_Rel;
            // if pasig filter is used show only the Assigned Personal used in the filter
            if (filters && 'pasig' in filters && filters.pasig) {
              p_rel = p_rel.filter(p => p === advisors.find(ad => ad.IDX === filters.pasig)?.VD_DENO);
            }
            p_rel.forEach((p, index) => {
              tasks.push({
                ...task,
                IdTarea: task.IdTarea + '_' + index,
                P_Rel: p,
              });
            })
          } else {
            // if there is not Assigned Personel use 'Asesor' as 'P_Rel'
            tasks.push({
              ...task,
              P_Rel: task.Asesor || 'No ingresado',
            });
          }
        })
        setTasks(tasks);
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setTasks([]);
          setLoading(false);
        }
      });
  };

  const fetchTaskFilters = () => {
    setLoadingFilters(true);
    getTaskFilters(source[1])
      .then(result => {
        setAdvisors(result.vendedores);
        setLocations(result.localizaciones);
        setEvents(result.eventos);
        setDepartments(result.departamentos);
        setLoadingFilters(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoadingFilters(false);
        }
      });
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    fetchTaskFilters();
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters && filters.id === new TaskFilters().id) {
      fetchTasks();
    }
    return () => {
      source[0].cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const ExpandContent = ({ task }: { task: any }) => (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2"><strong>Departamento</strong></Typography>
        <Typography variant="body2">{task.Departamento}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2"><strong>Asesor</strong></Typography>
        <Typography variant="body2">{task.Asesor || 'No ingresado'}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {
        filters && <FancyFilter
          noSearch
          useTabs
          tabKey="estado"
          tabElements={[{ value: '', name: 'Todos' }].concat(Object.keys(STATUS).map(key => ({
            value: key,
            name: STATUS[key].name
          })))}
          elements={filterElements}
          defaultValues={new TaskFilters()}
        />
      }
      {
        !loading && <FancyList
          expand
          elements={tasks}
          count={tasks.length}
          idKey="IdTarea"
          ListItemTextProps={(task: any) => ({
            primary: <FancyItemText textArray={[
              task.Tarea,
              task.P_Rel,
            ]} />,
            secondary: <FancyItemText textArray={[
              `${task.Fecha} ${task.Hora}`,
              task.Evento,
              STATUS[task.Estado] && <span className={STATUS[task.Estado].className}>
                {STATUS[task.Estado].name}
              </span>
            ]} />
          })}
          expandContent={(task: any) => <ExpandContent task={task} />}
        />
      }
      {loading && <FancyProgress color="primary" size={100} />}
    </React.Fragment >
  );
}
