import { BUTTON_SAVE_DISABLE, BUTTON_SAVE_ABLE } from '../types/buttonTypes';

export const buttonSaveAble = () => {
  return {
    type: BUTTON_SAVE_ABLE,
    payload: {
      buttonSave: true
    }
  };
};

export const buttonSaveDisable = () => {
  return {
    type: BUTTON_SAVE_DISABLE,
    payload: {
      buttonSave: false
    }
  };
};