import React from "react";
import { Route, Redirect } from "react-router-dom";


export const PublicRoute = ({
  component: Component,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (localStorage.getItem('token') ===  null){
          return <Component {...props} />;
        } 
        else {
          return (
            <Redirect
              to={{
                pathname: "/profile",
                state: {
                  from: props.location
                }
              }}
            />
          )
        }
      }}
    />
  );
};


