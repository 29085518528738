import React, { useEffect, useState } from 'react';
// Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Services
import { getGsProducts } from '../../services/ProductServices';
// Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { GsProductFilters } from '../../redux/types/filtersTypes';
// App
import { FancyProgress } from '../../utils/FancyComponents';
import FancyFilter, { FilterElement } from '../../utils/FancyFilter';
import FancyList from '../../utils/FancyList';
import products_default from '../../assets/media/images/default/products_default.jpg';
// Others
import axios, { CancelTokenSource } from 'axios';

const filterElements: FilterElement[] = [
  {
    type: 'Select',
    key: 'enabled',
    name: 'Filtros',
    selectItems: [
      { value: '', name: 'Todos' },
      { value: 'true', name: 'Activo' },
      { value: 'false', name: 'No Activo' }
    ],
  },
];

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function GsProductListComponent() {
  const filters = useSelector((state: RootState) => state.filters);
  const [products, setProducts] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const limit = 30;

  async function fetchProducts(reset: boolean = false) {
    if (reset) {
      setLoading(true);
    }
    const newPage = reset ? 0 : page;
    const params: any = {
      ...filters,
      limit: limit,
      offset: newPage * limit,
    }
    Object.keys(params).forEach((key: string) => {
      if (params[key] === '') {
        params[key] = null;
      }
    });
    source[0] = axios.CancelToken.source();
    getGsProducts(source[0], params)
      .then(response => {
        setHasMore(!!response.next);
        setPage(prev => reset ? 1 : prev + 1);
        setCount(response.count);
        setProducts(prev => reset ? response.results : prev.concat(response.results));
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setHasMore(false);
          setPage(0);
          setCount(0);
          setProducts([]);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters && filters.id === new GsProductFilters().id) {
      fetchProducts(true);
    }
    return () => {
      source[0].cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const ExpandContent = ({ product }: { product: any }) => (
    <Grid container spacing={1}>
      <Grid item sm={6} xs={12}>
        <Typography variant="subtitle2"><strong>Categoria</strong></Typography>
        <Typography variant="body2">{product.category__name}</Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Typography variant="subtitle2"><strong>Etiquetas</strong></Typography>
        <Typography variant="body2">
          {product.labels && product.labels.map((item: any) => item.name).join(', ')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2"><strong>Descripción</strong></Typography>
        <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>{
          product.description}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {
        filters && <FancyFilter
          noSearch
          elements={filterElements}
          defaultValues={new GsProductFilters()}
        />
      }
      {
        !loading && <FancyList
          infinite
          expand
          elements={products}
          count={count}
          hasMore={hasMore}
          fetchElements={fetchProducts}
          AvatarProps={(product: any) => ({
            variant: 'square',
            src: (product.image && product.image) || products_default,
          })}
          ListItemTextProps={(product: any) => ({
            primary: `${product.name}`,
            secondary: `${product.price}`
          })}
          expandContent={(product: any) => <ExpandContent product={product} />}
        />
      }
      {loading && <FancyProgress color="primary" size={100} />}
    </React.Fragment >
  );
}
