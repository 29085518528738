export const USER_AUTHENTICATE = 'USER_AUTHENTICATE';
export const USER_UNATHENTICATE = 'USER_UNATHENTICATE';
export const USER_CHECK_AUTHENTICATE = 'USER_CHECK_AUTHENTICATE';
export const SET_USER = "SET_USER";

export interface UserAuth {
  username: string
  loggeIn: boolean
  user: AuthUser
}

export class AuthUser {
  id: number | null = null;
  is_superuser: boolean = false;
  is_client: boolean = false;
  is_company: boolean = false;
  roles: string[] = [];
  first_name?: string = '';
  last_name?: string = '';
  username?: string = '';
  email?: string = '';
  avatar?: string = '';
  name?: string = '';
  alfardos_company_id: boolean = false;
  provider_type__name?: string = '';
  cities: any[] = [];
  provinces: any[] = [];
  social_reason: string = '';
  web?: string = '';
  facebook?: string = '';
  instagram?: string = '';
  tripadvisor?: string = '';
  description?: string = '';
  cover_image?: string = '';
  provider_schedule: Schedule = new Schedule();
}

class Schedule {
  id: number = 0;
  monday: boolean = false;
  tuesday: boolean = false;
  wednesday: boolean = false;
  thursday: boolean = false;
  friday: boolean = false;
  saturday: boolean = false;
  sunday: boolean = false;
  monday_start_time: string = '';
  monday_end_time: string = '';
  tuesday_start_time: string = '';
  tuesday_end_time: string = '';
  wednesday_start_time: string = '';
  wednesday_end_time: string = '';
  thursday_start_time: string = '';
  thursday_end_time: string = '';
  friday_start_time: string = '';
  friday_end_time: string = '';
  saturday_start_time: string = '';
  saturday_end_time: string = '';
  sunday_start_time: string = '';
  sunday_end_time: string = '';
}

interface logIn {
  type: typeof USER_AUTHENTICATE
  payload: UserAuth
}

interface logOut {
  type: typeof USER_UNATHENTICATE
  payload: UserAuth
}

interface setUser {
  type: typeof SET_USER
  payload: AuthUser
}

interface checkAuth {
  type: typeof USER_CHECK_AUTHENTICATE
  payload: UserAuth
}


export type AuthActionTypes = logIn | logOut | checkAuth | setUser