import axios, { CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import { showSuccessSnackBar } from '../redux/actions/ShowMessageActions';

export async function getTaxes(source: CancelTokenSource, params?: any) {
  return axios.get(`ivas/`, { params: params, cancelToken: source.token })
    .then(response => response.data);
}

export async function getTax(id: any, source: CancelTokenSource) {
  return axios.get(`ivas/${id}/`, { cancelToken: source.token })
    .then(response => response.data);
}

export async function registerTax(data: any, dispatch: Dispatch<any>, source: CancelTokenSource) {
  return axios.post('ivas/', data, { cancelToken: source.token })
    .then(response => {
      dispatch(showSuccessSnackBar('Registro exitoso'));
      return response.data;
    });
};

export async function updateTax(id: any, data: any, dispatch: Dispatch<any>, source: CancelTokenSource) {
  return axios.patch(`ivas/${id}/`, data, { cancelToken: source.token })
    .then(response => {
      dispatch(showSuccessSnackBar('Se atualizó la información con éxito'));
      return response.data;
    });
}