import { SET_ORDER_DATA, CLEAR_ORDER_DATA, Order } from "../types/orderTypes";

const initialState: Order = new Order();

export default function orderReducer(state = initialState, action: any): Order {
  switch (action.type) {
    case SET_ORDER_DATA:
      return {
        ...action.payload,
      };
    case CLEAR_ORDER_DATA:
      return initialState;
    default:
      return state;
  }
}