import React, { useEffect, useState } from 'react';
// Material UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Services
import { getCommercialActions, getCommercialActionsFilters } from '../../services/CommercialActionsServices';
// Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { CommercialActionFilters } from '../../redux/types/filtersTypes';
// App
import { FancyProgress } from '../../utils/FancyComponents';
import FancyList, { FancyItemText } from '../../utils/FancyList';
import FancyFilter, { FilterElement } from '../../utils/FancyFilter';
// Others
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) => createStyles({
  success: {
    color: theme.palette.success.main
  },
  warning: {
    color: theme.palette.warning.main
  },
}));

const STATUSES = [
  { value: '0', name: 'Todos' },
  { value: '1', name: 'Pendiente' },
  { value: '2', name: 'Resuelto' },
]

const source: CancelTokenSource[] = new Array(1).fill(axios.CancelToken.source());

export default function CommercialActionsListComponent() {
  const classes = useStyles();
  const filters = useSelector((state: RootState) => state.filters);
  const [commercialActions, setCommercialActions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<any[]>([]);
  const [advisors, setAdvisors] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [commercialActionTypes, setCommercialActionTypes] = useState<any[]>([]);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const STATUS: { [key: string]: { name: string, className: string } } = {
    P: { name: 'Pendiente', className: classes.warning },
    R: { name: 'Resuelto', className: classes.success },
  };
  const filterElements: FilterElement[] = [
    {
      type: 'DateRange',
      key: 'date',
      name: 'Fecha',
    },
    {
      type: 'Autocomplete',
      key: 'idev',
      name: 'Evento',
      autocompleteOptions: events.map(el => ({ value: el.IDX, name: el.EV_DENO })),
      loading: loadingFilters,
    },
    {
      type: 'Autocomplete',
      key: 'idas',
      name: 'Asesor',
      autocompleteOptions: advisors.map(el => ({ value: el.IDX, name: el.VD_DENO })),
      loading: loadingFilters,
    },
    {
      type: 'Autocomplete',
      key: 'idloc',
      name: 'Localización',
      autocompleteOptions: locations.map(el => ({ value: el.IDX, name: el.LZ_DENO })),
      loading: loadingFilters,
    },
    {
      type: 'Select',
      key: 'estev',
      name: 'Estado de evento',
      selectItems: [
        { value: '', name: 'Todos' },
        { value: 'L', name: 'Pendiente localización' },
        { value: 'N', name: 'Denegada localización' },
        { value: 'C', name: 'Captación' },
        { value: 'P', name: 'Planificación' },
        { value: 'E', name: 'Ejecución' },
        { value: 'B', name: 'Bloqueado' },
        { value: 'F', name: 'Finalizado' },
        { value: 'X', name: 'Fallido' },
      ],
    },
    {
      type: 'Autocomplete',
      key: 'tpacc',
      name: 'Tipo de acción comercial',
      autocompleteOptions: commercialActionTypes.map(el => ({ value: el.IDX, name: el.AX_DENO })),
      loading: loadingFilters,
    },
    {
      type: 'Select',
      key: 'tpcli',
      name: 'Tipo de cliente',
      selectItems: [
        { value: '0', name: 'Todos' },
        { value: '1', name: 'Cliente' },
        { value: '2', name: 'Futuro cliente' },
      ],
    },
    // {
    //   type: 'Checkbox',
    //   key: 'soloult',
    //   name: 'Acción',
    //   label: 'Mostrar última acción',
    // },
  ];

  const fetchCommercialActions = () => {
    setLoading(true);
    const params: any = { ...filters };
    if (filters && 'date' in filters && filters.date && filters.date[0]) {
      params['dfec'] = filters.date[0].format('DDMMYYYY');
      if (filters.date[1]) {
        params['hfec'] = filters.date[1].format('DDMMYYYY');
      }
    }
    delete params.date;
    source[0] = axios.CancelToken.source();
    getCommercialActions(source[0], params)
      .then(result => {
        setCommercialActions(result);
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setCommercialActions([]);
          setLoading(false);
        }
      });
  };

  const fetchCommercialActionsFilters = () => {
    setLoadingFilters(true);
    getCommercialActionsFilters(source[1])
      .then(result => {
        setEvents(result.eventos);
        setAdvisors(result.vendedores);
        setLocations(result.localizaciones);
        setCommercialActionTypes(result.tipos);
        setLoadingFilters(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoadingFilters(false);
        }
      });
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    fetchCommercialActionsFilters();
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters && filters.id === new CommercialActionFilters().id) {
      fetchCommercialActions();
    }
    return () => {
      source[0].cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const ExpandContent = ({ action }: { action: any }) => (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2"><strong>Cliente</strong></Typography>
        <Typography variant="body2">{action.Cliente}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2"><strong>Localización</strong></Typography>
        <Typography variant="body2">{action.Localizacion}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {
        filters && <FancyFilter
          noSearch
          useTabs
          tabKey="estado"
          tabElements={STATUSES}
          elements={filterElements}
          defaultValues={new CommercialActionFilters()}
        />
      }
      {
        !loading && <FancyList
          expand
          idKey="IdAc"
          elements={commercialActions}
          count={commercialActions.length}
          ListItemTextProps={(action: any) => ({
            primary: <FancyItemText textArray={[
              action.Evento,
              action.Asesor
            ]} />,
            secondary: <FancyItemText textArray={[
              action.Fecha,
              action.Tipo,
              STATUS[action.Estado] && <span className={STATUS[action.Estado].className}>
                {STATUS[action.Estado].name}
              </span>
            ]} />
          })}
          expandContent={(action: any) => <ExpandContent action={action} />}
        />
      }
      {loading && <FancyProgress color="primary" size={100} />}
    </React.Fragment >
  );
}
