import React from 'react';
// Material UI
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from "@material-ui/core/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { clearSnackbar } from '../redux/actions/ShowMessageActions'

export interface SnackbarMessage {
  message: string;
  key: number;
}

const ShowSnackbar = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state: RootState) => state.alert)
  const [open, setOpen] = React.useState(snackbar.open);
  const [snackPack, setSnackPack] = React.useState<SnackbarMessage[]>([]);
  const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(undefined);

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  React.useEffect(() => {
    setOpen(snackbar.open);
    if (snackbar.snackbarMessage) {
      setSnackPack((prev) => [...prev, { message: snackbar.snackbarMessage, key: new Date().getTime() }]);
    }
  }, [snackbar]);

  function handleClose() {
    setOpen(false);
    dispatch(clearSnackbar());
  }

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      onClose={handleClose}
      onExited={handleExited}
      message={messageInfo ? messageInfo.message : undefined}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseRoundedIcon />
        </IconButton>
      ]}
    />
  );
}

export default ShowSnackbar;