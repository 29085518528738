//v1.3
import React, { useState, useEffect } from 'react';
// Material UI
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { ButtonProps } from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { DateRange, StaticDateRangePickerProps, StaticDateRangePicker, DateRangeDelimiter } from '@material-ui/pickers'
// App
import { FancyButton, useFancyStyles, FancyTextInput } from './FancyComponents';
import { getDateFromRange } from './date-methods';
// Other
import moment, { Moment } from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverPaper: {
      '@media (max-width: 336px)': {
        maxWidth: 'inherit'
      }
    },
    dateRangePicker: {
      '& .MuiPickersDateRangePickerToolbarProps-dateTextContainer': {
        alignItems: 'center',
      }
    },
  })
);

interface ListItemInterface {
  name: string,
  value: DateRange<Moment>
}

interface DateRangePickerPopoverProps {
  initialValue: DateRange<Moment>;
  onSubmit(date: DateRange<Moment>): void;
  onClose?(): void;
  DateRangeProps?: Omit<StaticDateRangePickerProps, 'renderInput' | 'onChange' | 'value'>;
  ButtonProps?: Omit<ButtonProps, 'onClick'>
}

const listItems: ListItemInterface[] = [
  { name: 'Hoy', value: [moment.utc(), moment.utc()] },
  { name: 'Ayer', value: [moment.utc().subtract(1, 'd'), moment.utc().subtract(1, 'd')] },
  { name: 'Esta Semana', value: [moment.utc().startOf('week'), moment.utc().endOf('week')] },
  { name: 'Semana Pasada', value: [moment.utc().subtract(1, 'w').startOf('week'), moment.utc().subtract(1, 'w').endOf('week')] },
  { name: 'Este Mes', value: [moment.utc().startOf('month'), moment.utc().endOf('month')] },
  { name: 'Mes Pasado', value: [moment.utc().subtract(1, 'M').startOf('month'), moment.utc().subtract(1, 'M').endOf('month')] },
  { name: 'Este Año', value: [moment.utc().startOf('year'), moment.utc().endOf('year')] },
];

export default function DateRangePickerPopover(props: DateRangePickerPopoverProps) {
  const styles = useFancyStyles();
  const classes = useStyles();
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesXXS = useMediaQuery('(max-width: 336px)');
  const [date, setDate] = useState<DateRange<Moment>>(props.initialValue);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (popoverAnchor) {
      setDate(props.initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popoverAnchor]);

  const isSelected = (value: DateRange<Moment>): boolean => {
    const startIsSelected = date[0] ? date[0].isSame(value[0], 'd') : date[0] === value[0];
    const endIsSelected = date[1] ? date[1].isSame(value[1], 'd') : date[1] === value[1];

    return startIsSelected && endIsSelected;
  };

  const handleDateChange = (newValue: any) => {
    if (newValue[0]) {
      newValue[0] = newValue[0].utc();
    }
    if (newValue[1]) {
      newValue[1] = newValue[1].utc();
    }
    setDate(newValue);
  };

  const onCloseHandler = () => {
    setPopoverAnchor(null);
    if (props.onClose) {
      props.onClose();
    }
  }

  return (
    <React.Fragment>
      <FancyButton
        startIcon={<CalendarTodayIcon />}
        variant="outlined"
        onClick={(event: any) => setPopoverAnchor(event.currentTarget)}
        {...props.ButtonProps}
      >
        {props.initialValue[0] ? getDateFromRange(props.initialValue, 'DD/MM/YYYY') : 'Sin fecha'}
      </FancyButton>
      <Popover
        open={!!popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        PaperProps={{ className: classes.popoverPaper }}
        marginThreshold={!matchesXXS ? 16 : 0}
        anchorEl={popoverAnchor}
        anchorReference={!matchesXXS ? 'anchorEl' : 'anchorPosition'}
        anchorPosition={{ top: popoverAnchor?.offsetTop ?? 0, left: 0 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid container direction="row" alignItems="stretch">
          <Hidden xsDown>
            <Grid item xs container direction="column">
              <List component="nav">
                {
                  listItems.map(item => (
                    <ListItem
                      button
                      key={item.name}
                      onClick={() => setDate(item.value)}
                      selected={isSelected(item.value)}
                    >
                      <ListItemText primary={item.name} />
                    </ListItem>
                  ))
                }
              </List>
            </Grid>
          </Hidden>
          <Grid item xs container direction="column">
            <Grid item>
              <StaticDateRangePicker
                displayStaticWrapperAs={matchesDesktop ? 'desktop' : 'mobile'}
                value={date}
                onChange={handleDateChange}
                startText="Inicio"
                endText="Fin"
                toolbarTitle="Selecciona un rango de fechas"
                className={classes.dateRangePicker}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <FancyTextInput {...startProps} helperText="" />
                    <DateRangeDelimiter> a </DateRangeDelimiter>
                    <FancyTextInput {...endProps} helperText="" />
                  </React.Fragment>
                )}
                {...props.DateRangeProps}
              />
            </Grid>
            <Grid item container direction="row" justify="flex-end" className={styles.p_1}>
              <Grid item className={styles.mr_1}>
                <FancyButton
                  variant="outlined"
                  color="primary"
                  onClick={onCloseHandler}
                >
                  Cancelar
                </FancyButton>
              </Grid>
              <Grid item>
                <FancyButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.onSubmit(date);
                    onCloseHandler();
                  }}
                >
                  Ok
                </FancyButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </React.Fragment>
  )
};