import React, { useState } from 'react';
import clsx from 'clsx';

//Material UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Menu,
  MenuItem,
  Grid,
  Button,
  Hidden
} from '@material-ui/core';

import { SidebarNavigation } from '../utils/SidebarNavigation';

//Images
import logo from "../assets/media/tke.svg"
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

//Service
import { logout } from '../services/AuthServices'
//React Router Dom
import { useHistory, Link } from 'react-router-dom';
//Redux
import { useDispatch } from 'react-redux';

const drawerWidth = 245;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      background: theme.palette.primary.contrastText,
      [theme.breakpoints.up('sm')]: {
        padding: '0 45px',
      },
      [theme.breakpoints.down('sm')]: {
        '& .MuiToolbar-root': {
          justifyContent: 'space-between',
        }
      }
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logo: {
      height: 52,
      [theme.breakpoints.up('sm')]: {
        height: 60,
      },
    },
    hide: {
      display: 'none',
    },
    drawer: {
      /*'& .widthDrawer': {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',        
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.contrastText,
      }*/
      width: drawerWidth,
      flexShrink: 0,
      /*whiteSpace: 'nowrap',*/
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.contrastText,
      }
    },
    drawerOpen: {
      /*'& .widthDrawer': {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),        
      },
      '& .buttonOpenDrawer': {
          display: 'none',
      },*/
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '& .buttonOpenDrawer': {
        display: 'none',
      },
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      '& .makeStyles-titleSidebar-12': {
        '& .MuiListItemText-root': {
          display: 'none',
        }
      },
      '& .buttonCloseDrawer, .textOpenDrawer': {
        display: 'none',
      },
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    drawerPaper: {
      marginTop: '64px',
      background: theme.palette.primary.main,
      paddingBottom: '64px',
      '& .MuiTypography-body1': {
        color: theme.palette.primary.contrastText,
      },
      '& .MuiAccordion-root': {
        background: 'transparent',
        boxShadow: 'none',
        '& .MuiIconButton-root': {
          color: theme.palette.primary.contrastText,
        },
        '& .MuiAccordionSummary-content': {
          margin: '0',
          '& .MuiListItem-root': {
            paddingLeft: '0',
            paddingBottom: '0',
          }
        },
        '& .MuiAccordionDetails-root, ul': {
          paddingTop: '0',
          paddingBottom: '0',
        }
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '0',
      }
    },
    titleSidebar: {
      '& .MuiTypography-body1': {
        fontSize: '1.2rem',
      }
    },
    content: {
      width: `calc(100vw - (${drawerWidth}px + ${theme.spacing(2)}px))`,
      flexGrow: 1,
      padding: theme.spacing(0, 2),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      //marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    actions: {
      position: 'fixed',
      bottom: '1em',
      right: '2em',
    },
    rightMenu: {
      marginLeft: 'auto',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 'initial',
        '& .MuiIconButton-colorSecondary': {
          paddingLeft: '0',
          paddingRight: '0',
        }
      }
    },
    logout: {
      color: theme.palette.primary.main,
      fontWeight: 900,
      textTransform: 'initial',
      fontSize: '1.2rem',
      background: 'transparent!important',
      '& svg': {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '100%',
        marginLeft: '10px',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        paddingRight: '0',
        minWidth: 'auto',
        '& svg': {
          marginLeft: '0',
        },
        '& .MuiButton-label': {
          fontSize: '0',
        }
      }
    },
    headerComponent: {
      display: 'flex',
      '& h2': {
        color: theme.palette.primary.main,
        fontSize: '1.75rem',
        marginBottom: '15px',
      }
    },
    headerButtom: {
      marginLeft: 'auto',
      alignSelf: 'center',
    },
    divider: {
      background: theme.palette.primary.main,
      height: '2px',
      marginBottom: theme.spacing(1)
    }
  }),
);

export default function DasboardComponent(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const theme = useTheme();
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    // history.push('/information')
    setAnchorEl(null);
  };

  /*const handleDrawerOpen = () => {
    if (open === true) {
      setOpen(false);
    }
    else {
      setOpen(true);
    }
  };*/

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
     setOpen(false);
  };

  const handlerLogOut = () => {
    logout(history, dispatch, 'Ha cerrado sesión exitosamente');
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar)}
      >
        <Toolbar>
          <Hidden smUp implementation="css">
            <IconButton color="primary" onClick={handleDrawerToggle}
            //className={clsx(!open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <img src={logo} alt="take events" className={classes.logo} />
          <Grid className={classes.rightMenu}>
            <IconButton color="secondary">
              <NotificationsIcon />
            </IconButton>
            <Button
              color="inherit"
              onClick={handleMenu}
              className={classes.logout}>
              Mi cuenta
                <PersonIcon color="secondary" />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem component={Link} to="/profile">Mi Perfil</MenuItem>
              <MenuItem onClick={handlerLogOut}>Cerrar sesión</MenuItem>
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
      <Hidden smUp implementation="css">
        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Divider />
          
          <List>
            <ListItem button className={classes.titleSidebar}>
              <ListItemText>
                ¡Bienvenido!
                </ListItemText>
              <ListItemIcon>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                >
                  <CloseIcon />
                </IconButton>
              </ListItemIcon>
            </ListItem>
            <SidebarNavigation />
          </List>
          
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          //className={classes.drawer}
          variant="permanent"
          anchor="left"
          //open={open}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          /*classes={{
            paper: classes.drawerPaper,
          }}*/
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <Divider />
          
          <List>
            <ListItem button className={classes.titleSidebar}>
              <ListItemText className="textOpenDrawer">
                ¡Bienvenido!
                </ListItemText>
              <ListItemIcon>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerClose}
                  className="buttonCloseDrawer"
                >
                  <MenuIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerOpen}
                  className="buttonOpenDrawer"
                >
                  <ChevronRightIcon />
                </IconButton>
              </ListItemIcon>
            </ListItem>
            <SidebarNavigation />
          </List>
          
        </Drawer>
      </Hidden>
      <div className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}>
        <Toolbar></Toolbar>
        <div className="title">
          <Grid className={classes.headerComponent}>
            {props.title}
            <div
              className={classes.headerButtom}>
              {props.headerButtom}
            </div>
          </Grid>
        </div>
        <Divider className={classes.divider} />
        <div className="component">
          {props.children}
        </div>
        <div className={classes.actions} >
          {props.actions}
        </div>
      </div>
    </div>
  );
}
