export const SCOPES: { [key: string]: string } = {
    ROLE_READ: "role:read",
    ROLE_CREATE: "role:create",
    ROLE_EDIT: "role:edit",
    ROLE_DELETE: "role:delete",
    USER_READ: "user:read",
    USER_CREATE: "user:create",
    USER_EDIT: "user:edit",
    USER_DELETE: "user:delete",
    TASK_READ: "task:read",
    TASK_CREATE: "task:create",
    TASK_EDIT: "task:edit",
    TASK_DELETE: "task:delete",
  };