import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/store";
import { AuthUser } from "../redux/types/authTypes";
import { authenticated } from "../services/AuthServices";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";


export const ProtectedRoute = ({
  component: Component,
  ...rest
}: any) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const user: AuthUser = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      authenticated(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {
        (!localStorage.getItem('token') || user.id) ? (
          <Route
            {...rest}
            render={props => {
              let hasScope = true;
              if (rest.scopes) {
                rest.scopes.some((scope: string) => {
                  if (user.roles.includes(scope)) {
                    hasScope = true;
                    return true;
                  }
                  hasScope = false;
                  return false;
                });
              }
              if (localStorage.getItem('token') && hasScope && (rest.path[0] !== '/users' || (user.is_superuser && rest.path[0] === '/users'))) {
                return <Component {...props} />;
              } else {
                history.push('/');
              }
            }}
          />
        ) : (
            <Grid container justify="center" alignItems="center" style={{ height: '100vh' }}>
              <Grid item><CircularProgress size={150} /></Grid>
            </Grid>
          )
      }
    </React.Fragment>
  );
};