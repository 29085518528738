import axios, { CancelTokenSource } from 'axios';

export async function getJobs(source: CancelTokenSource, params?: any) {
  return axios.get(`gs/jobs/`, { params: params, cancelToken: source.token })
    .then(response => response.data);
};

export async function getJobsFilters(source: CancelTokenSource, params?: any) {
  return axios.get(`gs/filters/jobs/`, { params: params, cancelToken: source.token })
    .then(response => response.data);
};