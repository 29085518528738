// v1.0
import moment, { Moment } from 'moment';
import { DateRange } from '@material-ui/pickers/DateRangePicker/RangeTypes';

const twoDigitString = (number: number) => number < 10 ? '0' + number : number;

export const getTimeFromSec = (seconds: number) => {
  const duration = moment.duration(seconds, 's');
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const sec = duration.seconds();
  return twoDigitString(hours) + ':' + twoDigitString(minutes) + ':' + twoDigitString(sec);
};

export const getDateFromRange = (date: DateRange<Moment>, format: string): string => {
  const today = moment.utc().format(format);
  const startDate = date[0] ? date[0].format(format) : null;
  const endDate = date[1] ? date[1].format(format) : null;
  if (!endDate) {
    return 'Desde ' + (startDate === today ? 'Hoy' : startDate);
  } else if (!startDate) {
    return 'Hasta ' + (endDate === today ? 'Hoy' : endDate);
  } else if (startDate === endDate) {
    if (startDate === today) {
      return 'Hoy';
    }
    return startDate;
  } else {
    return (startDate === today ? 'Hoy' : startDate) + ' - ' + (endDate === today ? 'Hoy' : endDate);
  }
};