import React, { useState, useEffect } from 'react';
// Material UI
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
// Forms
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
// App
import { FancyTextInput, FancyButton, PasswordTextInput, FancyProgress } from '../../utils/FancyComponents';
// Redux
import { dismissDialogEdit, dismissDialog } from '../../redux/actions/DialogActions';
import { useDispatch } from 'react-redux';
// Services
import { getUser, updateUser, registerUser } from '../../services/UserServices';
// Others
import axios, { CancelTokenSource } from 'axios';

interface UserFormComponentProps {
  fetchUsers: () => void;
  userId: number | null;
}

let source: CancelTokenSource = axios.CancelToken.source();

export default function UserFormComponent(props: UserFormComponentProps) {
  const dispatch = useDispatch();
  const isEdit = !!props.userId;
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const schema = yup.object().shape({
    idx: yup.string().required(),
    email: yup.string().required().email(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    password: !isEdit ? yup.string().required() : yup.string(),
    passwordConfirm: yup.string().oneOf([yup.ref('password')], 'La confirmación de la contraseña no coincide'),
  });
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const { handleSubmit, control, errors, reset } = methods;

  const fetchUser = () => {
    setLoading(true);
    getUser(props.userId, source)
      .then(user => {
        reset(user);
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoading(false);
        }
      });
  }

  useEffect(() => {
    source = axios.CancelToken.source();
    if (isEdit) {
      fetchUser();
    }
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: any) => {
    setBusy(true);
    data['username'] = data.email;
    Object.keys(data).forEach(key => {
      if (data[key] === '') {
        data[key] = undefined;
      }
    });
    const service = isEdit ? updateUser(props.userId, data, dispatch, source)
      : registerUser(data, dispatch, source);
    service
      .then(response => {
        props.fetchUsers();
        dispatch(dismissDialog());
        dispatch(dismissDialogEdit());
        setBusy(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {
        !loading && <Grid container direction="row" justify="center" spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Información Personal</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              as={FancyTextInput}
              control={control}
              error={!!errors.first_name}
              helperText={errors.first_name && errors.first_name.message}
              defaultValue=""
              name="first_name"
              label="Nombre"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              as={FancyTextInput}
              error={!!errors.last_name}
              helperText={errors.last_name && errors.last_name.message}
              control={control}
              defaultValue=""
              name="last_name"
              label="Apellido"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              as={FancyTextInput}
              control={control}
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
              defaultValue=""
              name="email"
              type="email"
              label="Email"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              as={FancyTextInput}
              control={control}
              error={!!errors.idx}
              helperText={errors.idx && errors.idx.message}
              defaultValue=""
              name="idx"
              label="IDx"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              as={PasswordTextInput}
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              control={control}
              defaultValue=""
              autoComplete="current-password"
              name="password"
              label="Ingrese contraseña"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              as={PasswordTextInput}
              error={!!errors.passwordConfirm}
              helperText={errors.passwordConfirm && errors.passwordConfirm.message}
              control={control}
              defaultValue=""
              name="passwordConfirm"
              label="Confirme la contraseña"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="is_superuser"
              control={control}
              defaultValue={false}
              render={props => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onBlur={props.onBlur}
                      onChange={e => props.onChange(e.target.checked)}
                      checked={props.value}
                    />
                  }
                  label="¿Es Superadministrador?"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} container direction="row" justify="center">
            <FancyButton
              variant="contained"
              color='primary'
              type="submit"
              disabled={busy}
              loading={busy}
            >
              {isEdit ? 'Editar' : 'Crear'}
            </FancyButton>
          </Grid>
        </Grid>
      }
      {loading && <FancyProgress size={100} />}
    </form >
  );
}
