import axios, { CancelTokenSource } from 'axios';

export async function getTasks(source: CancelTokenSource, params?: any) {
  return axios.get(`gs/tasks/`, { params: params, cancelToken: source.token })
    .then(response => response.data);
};

export async function getTaskFilters(source: CancelTokenSource, params?: any) {
  return axios.get(`gs/filters/tasks/`, { params: params, cancelToken: source.token })
    .then(response => response.data);
};