import React, { useEffect, useState } from 'react';
// Material UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import GetAppIcon from '@material-ui/icons/GetApp';
// Services
import { getBudgets, getBudgetsPdf } from '../../services/BudgetServices';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { BudgetFilters } from '../../redux/types/filtersTypes';
import { showSuccessSnackBar } from '../../redux/actions/ShowMessageActions';
import { showDialog, dismissDialog } from '../../redux/actions/DialogActions';
// App
import { FancyModal, FancyProgress } from '../../utils/FancyComponents';
import FancyList, { FancyItemText, ActionButton } from '../../utils/FancyList';
import ChangeStatusModalComponent from './ChangeStatusModalComponent';
import FancyFilter, { FilterElement } from '../../utils/FancyFilter';
// Others
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) => createStyles({
  success: {
    color: theme.palette.success.main
  },
  warning: {
    color: theme.palette.warning.main
  },
  error: {
    color: theme.palette.error.main
  },
}));

const filterElements: FilterElement[] = [
  {
    type: 'DateRange',
    key: 'date',
    name: 'Fecha',
  },
  {
    type: 'Select',
    key: 'estado',
    name: 'Estado',
    selectItems: [
      { value: '0', name: 'Todos' },
      { value: '1', name: 'Pendiente Revisión' },
      { value: '2', name: 'Pendiente Dirección' },
      { value: '3', name: 'Pendiente Cliente' },
      { value: '4', name: 'Aprobado Cliente' },
    ],
  },
  {
    type: 'Select',
    key: 'tpcli',
    name: 'Tipo de Cliente',
    selectItems: [
      { value: '0', name: 'Todos' },
      { value: '1', name: 'Cliente' },
      { value: '2', name: 'Futuro Cliente' },
    ],
  },
];

const STATUS = [
  { value: '0', name: 'Todos' },
  { value: '1', name: 'Pediente' },
  { value: '2', name: 'Aprobado' },
  { value: '3', name: 'Denegado' },
];

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function BudgetListComponent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialog = useSelector((state: RootState) => state.dialog);
  const user = useSelector((state: RootState) => state.auth.user);
  const filters = useSelector((state: RootState) => state.filters);
  const [budgets, setBudgets] = useState<any[]>([]);
  const [selectedBudget, setSelectedBudget] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const [type, setType] = useState<'approved' | 'denied' | ''>('');
  const actionButtons: ActionButton[] = [
    {
      text: 'Aprobar',
      icon: <CheckCircleOutlineIcon />,
      onClick: (event: any, budget: any) => handleOptions(event, budget, 'approved'),
      disabled: busy,
      show: (budget) => user.is_superuser && budget.Aprobacion === 'Pendiente'
    },
    {
      text: 'Denegar',
      icon: <BlockIcon />,
      onClick: (event: any, budget: any) => handleOptions(event, budget, 'denied'),
      disabled: busy,
      show: (budget) => user.is_superuser && budget.Aprobacion === 'Pendiente'
    },
    {
      text: 'Descargar PDF',
      icon: <GetAppIcon />,
      onClick: (event: any, budget: any) => handleOptions(event, budget, 'download'),
      disabled: busy,
    },
  ];

  const fetchBudgets = () => {
    setLoading(true);
    const params: any = { ...filters };
    if (filters && 'date' in filters && filters.date && filters.date[0]) {
      params['dfec'] = filters.date[0].format('DDMMYYYY');
      if (filters.date[1]) {
        params['hfec'] = filters.date[1].format('DDMMYYYY');
      }
    }
    delete params.date;
    source[0] = axios.CancelToken.source();
    getBudgets(source[0], params)
      .then(result => {
        setBudgets(result);
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setBudgets([]);
          setLoading(false);
        }
      })
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters && filters.id === new BudgetFilters().id) {
      fetchBudgets();
    }
    return () => {
      source[0].cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleClose = () => {
    dispatch(dismissDialog());
    setSelectedBudget({});
    setType('');
  }

  const handleOptions = (event: any, budget: any, option: 'approved' | 'denied' | 'download') => {
    event.stopPropagation();
    setSelectedBudget(budget);
    switch (option) {
      case 'approved':
        setType('approved');
        dispatch(showDialog())
        return;
      case 'denied':
        setType('denied');
        dispatch(showDialog())
        return;
      case 'download':
        setBusy(true);
        dispatch(showSuccessSnackBar('En unos momentos se descargará el archivo'));
        getBudgetsPdf(source[1], { budget: budget.IdPr })
          .then(response => setBusy(false))
          .catch(error => {
            if (!error.isCanceled) {
              setBusy(false);
            }
          });
        return;
    }
  };

  const getColor = (state: string) => {
    switch (state) {
      case 'Pendiente':
        return classes.warning;
      case 'Aprobado':
        return classes.success;
      case 'Denegado':
        return classes.error;
      default:
        return '';
    }
  };

  const ExpandContent = ({ budget }: { budget: any }) => (
    <Grid container spacing={1}>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Cliente</strong></Typography>
        <Typography variant="body2">{budget.Cliente}</Typography>
      </Grid>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Asesor</strong></Typography>
        <Typography variant="body2">{budget.Asesor}</Typography>
      </Grid>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Importe</strong></Typography>
        <Typography variant="body2">{budget.Importe}</Typography>
      </Grid>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Cliente</strong></Typography>
        <Typography variant="body2">{budget.IdCl}</Typography>
      </Grid>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Evento</strong></Typography>
        <Typography variant="body2">{budget.IdEv}</Typography>
      </Grid>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Producto</strong></Typography>
        <Typography variant="body2">{budget.IdPr}</Typography>
      </Grid>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>TP</strong></Typography>
        <Typography variant="body2">{budget.Tp}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {
        filters && <FancyFilter
          noSearch
          useTabs
          tabKey="dir"
          tabElements={STATUS}
          busy={busy}
          elements={filterElements}
          defaultValues={new BudgetFilters()}
        />
      }
      {
        !loading && <FancyList
          expand
          elements={budgets}
          count={budgets.length}
          idKey="IdPr"
          ListItemTextProps={(budget: any) => ({
            primary: `${budget['Evento Relacionado']}`,
            secondary: <FancyItemText textArray={[
              budget.Fecha,
              budget.Estado,
              <Typography component="span" className={getColor(budget.Aprobacion)}>{budget.Aprobacion}</Typography>
            ]} />
          })}
          actionButtons={actionButtons}
          expandContent={(budget: any) => <ExpandContent budget={budget} />}
        />
      }
      {loading && <FancyProgress color="primary" size={100} />}
      <FancyModal open={dialog.open} onClose={handleClose} >
        <DialogContent>
          <ChangeStatusModalComponent
            fetchBudgets={() => fetchBudgets()}
            budgetId={selectedBudget.IdPr}
            type={type}
          />
        </DialogContent>
      </FancyModal>
    </React.Fragment >
  );
}
