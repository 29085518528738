import React from 'react';
import DashboardComponent from '../../components/DashboardComponent';
import ProfileDetails from '../../components/Profile/ProfileDetails';

export default function ProfileView() {
  return (
    <DashboardComponent
      title={<h2>Mi Perfil</h2>}
      children={<ProfileDetails />}
    />
  );
}