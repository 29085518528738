import React, { useEffect, useState } from 'react';
// Material UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import HowToRegIcon from '@material-ui/icons/HowToReg';
// Services
import { getUsers, deleteUser } from '../../services/UserServices';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { UserFilters } from '../../redux/types/filtersTypes';
import { showDialogEdit, dismissDialogEdit, dismissDialog, showDialog } from '../../redux/actions/DialogActions'
// App
import { FancyModal, FancyProgress } from '../../utils/FancyComponents';
import FancyList, { ActionButton, FancyItemText } from '../../utils/FancyList';
import FancyFilter, { FilterElement } from '../../utils/FancyFilter';
import UserFormComponent from './UserFormComponent';
// Others
import moment from 'moment';
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    position: 'fixed',
    bottom: '3%',
    right: '3%'
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
}));

const filterElements: FilterElement[] = [
  {
    type: 'Select',
    key: 'is_active',
    name: 'Estado',
    selectItems: [
      { value: '', name: 'Todos' },
      { value: 'true', name: 'Activo' },
      { value: 'false', name: 'No Activo' }
    ],
  },
];

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function UserListComponent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialog = useSelector((state: RootState) => state.dialog);
  const filters = useSelector((state: RootState) => state.filters);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const limit = 30;
  const actionButtons: ActionButton[] = [
    {
      text: 'Editar',
      icon: <EditIcon />,
      onClick: (event: any, user: any) => handleOptions('edit', user.id, event),
      disabled: busy,
    },
    {
      text: 'Cambiar estado',
      icon: <HowToRegIcon />,
      onClick: (event: any, user: any) => handleOptions('delete', user.id, event),
      disabled: busy,
    },
  ];

  async function fetchUsers(reset: boolean = false) {
    if (reset) {
      setLoading(true);
    }
    const newPage = reset ? 0 : page;
    const params = {
      ...filters,
      limit: limit,
      offset: newPage * limit,
    }
    source[0] = axios.CancelToken.source();
    getUsers(source[0], params)
      .then(response => {
        setHasMore(!!response.next);
        setPage(prev => reset ? 1 : prev + 1);
        setCount(response.count);
        setUsers(prev => reset ? response.results : prev.concat(response.results));
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setHasMore(false);
          setPage(0);
          setCount(0);
          setUsers([]);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters && filters.id === new UserFilters().id) {
      fetchUsers(true);
    }
    return () => {
      source[0].cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleClickOpen = () => {
    dispatch(showDialog());
  };

  const handleClose = () => {
    dispatch(dismissDialog());
    dispatch(dismissDialogEdit());
  };

  const handleOptions = (option: 'edit' | 'delete', id: number, event: any) => {
    event.stopPropagation();
    switch (option) {
      case 'edit':
        setSelectedUserId(id);
        dispatch(showDialogEdit());
        break;
      case 'delete':
        setBusy(true);
        deleteUser(id, source[1])
          .then(() => {
            fetchUsers(true);
            setBusy(false);
          })
          .catch(error => {
            if (!error.isCanceled) {
              setBusy(false);
            }
          })
        break;
    }
  };

  const getStatus = (status: boolean) => {
    if (status) {
      return 'Activo';
    } else {
      return 'No activo';
    }
  };

  const getUserIcon = (isSuperuser: Boolean) => {
    if (isSuperuser) {
      return <SupervisedUserCircleIcon fontSize="large" />
    }
    return <AccountCircleIcon fontSize="large" />
  }

  const ExpandContent = ({ user }: { user: any }) => (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2"><strong>Nombre</strong></Typography>
        <Typography variant="body2">{user.first_name}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2"><strong>Apellido</strong></Typography>
        <Typography variant="body2">{user.last_name}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2"><strong>Estado</strong></Typography>
        <Typography variant="body2">{getStatus(user.is_active)}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {
        filters && <FancyFilter
          busy={busy}
          elements={filterElements}
          defaultValues={new UserFilters()}
        />
      }
      {
        !loading && <FancyList
          infinite
          expand
          elements={users}
          count={count}
          hasMore={hasMore}
          fetchElements={fetchUsers}
          AvatarProps={(user: any) => ({
            className: user.is_active ? classes.primary : classes.error,
            children: getUserIcon(user.is_superuser),
          })}
          ListItemTextProps={(user: any) => ({
            primary: `${user.first_name} ${user.last_name} (${user.idx})`,
            secondary: <FancyItemText textArray={[
              user.email,
              moment.utc(user.date_joined).format('MMM. DD, YYYY')
            ]} />
          })}
          actionButtons={actionButtons}
          expandContent={(user: any) => <ExpandContent user={user} />}
        />
      }
      {loading && <FancyProgress color="primary" size={100} />}
      <Fab color="primary" aria-label="add" className={classes.button} onClick={handleClickOpen}>
        <AddIcon />
      </Fab>
      <FancyModal open={dialog.open || dialog.openEdit} onClose={handleClose} >
        <DialogContent>
          <UserFormComponent fetchUsers={() => fetchUsers(true)} userId={dialog.openEdit && selectedUserId} />
        </DialogContent>
      </FancyModal>
    </React.Fragment>
  );
}