import axios, { CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import { showSuccessSnackBar } from '../redux/actions/ShowMessageActions';

export async function getUsers(source: CancelTokenSource, params?: any) {
  return axios.get(`users/`, { params: params, cancelToken: source.token })
    .then(response => response.data);
}

export async function getUser(id: any, source: CancelTokenSource) {
  return axios.get(`users/${id}/`, { cancelToken: source.token })
    .then(response => response.data);
}

export async function registerUser(data: any, dispatch: Dispatch<any>, source: CancelTokenSource) {
  return axios.post('users/', data, { cancelToken: source.token })
    .then(response => {
      dispatch(showSuccessSnackBar('Registro exitoso'));
      return response.data;
    });
};

export async function updateUser(id: any, data: any, dispatch: Dispatch<any>, source: CancelTokenSource) {
  return axios.patch(`users/${id}/`, data, { cancelToken: source.token })
    .then(response => {
      dispatch(showSuccessSnackBar('Se atualizó la información con éxito'));
      return response.data;
    });
}

export async function deleteUser(id: number, source: CancelTokenSource) {
  return axios.delete(`users/${id}/`, { cancelToken: source.token })
    .then(response => response.data);
}