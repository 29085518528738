import React from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { JobsFilters } from '../../../redux/types/filtersTypes';
import { setFilters, resetFilters } from '../../../redux/actions/FiltersActions';
// App
import DashboardComponent from '../../../components/DashboardComponent';
import JobsListComponent from '../../../components/Jobs/JobsListComponent';
// Other
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

export default function JobsRouting(props: RouteComponentProps) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setFilters(new JobsFilters()));
    return () => {
      dispatch(resetFilters());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardComponent
      title={<h2>Partes de Trabajo</h2>}
      children={<Switch>
        <Route exact path={props.match.path + '/list'} component={JobsListComponent} />
        <Redirect to={props.match.path + '/list'} />
      </Switch>}
    />
  );
}
