import { combineReducers } from 'redux';
import authReducer from './reducers/authReducer';
import alertReducer from './reducers/alertReducer';
import dialogReducer from './reducers/dialogReducer';
import requestEventsReducer from './reducers/requestEventsReducer';
import buttonSaveReducer from './reducers/buttonSaveReducer';
import orderReducer from './reducers/orderReducer';
import filtersReducer from './reducers/filtersReducer';

const rootReducers = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  dialog: dialogReducer,
  requestEvents: requestEventsReducer,
  saveButton: buttonSaveReducer,
  order: orderReducer,
  filters: filtersReducer,
});

export type RootState = ReturnType<typeof rootReducers>;
export default rootReducers;
