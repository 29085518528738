import React, { useState, useEffect } from 'react';
// Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Forms
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
// App
import { FancyTextInput, FancyButton, FancyProgress } from '../../utils/FancyComponents';
// Redux
import { dismissDialogEdit, dismissDialog } from '../../redux/actions/DialogActions';
import { useDispatch } from 'react-redux';
// Services
import { getTax, updateTax, registerTax } from '../../services/TaxServices';
// Others
import axios, { CancelTokenSource } from 'axios';

const schema = yup.object().shape({
  name: yup.string().required(),
  percentage: yup.string().required(),
});

interface TaxFormComponentProps {
  fetchTaxes: () => void;
  taxId: number | null;
}

let source: CancelTokenSource = axios.CancelToken.source();

export default function TaxFormComponent(props: TaxFormComponentProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const { handleSubmit, control, errors, reset } = methods;
  const isEdit = !!props.taxId;

  const fetchTax = () => {
    setLoading(true);
    getTax(props.taxId, source)
      .then((tax: any) => {
        reset({
          name: tax.name,
          percentage: tax.percentage,
        })
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoading(false);
        }
      });
  }

  useEffect(() => {
    source = axios.CancelToken.source();
    if (isEdit) {
      fetchTax();
    }
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: any) => {
    setBusy(true);
    const service = isEdit ? updateTax(props.taxId, data, dispatch, source)
      : registerTax(data, dispatch, source);
    service
      .then(response => {
        props.fetchTaxes();
        dispatch(dismissDialog());
        dispatch(dismissDialogEdit());
        setBusy(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  }

  return (
    <React.Fragment>
      {
        !loading && <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6">Información del impuesto</Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={FancyTextInput}
                control={control}
                error={errors.name}
                helperText={errors.name && errors.name.message}
                defaultValue=""
                name="name"
                label="Nombre"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={FancyTextInput}
                control={control}
                error={errors.percentage}
                helperText={errors.percentage && errors.percentage.message}
                defaultValue=""
                name="percentage"
                label="Porcentaje"
              />
            </Grid>
            <Grid item xs={12} container direction="row" justify="center">
              <Grid item>
                <FancyButton
                  variant="contained"
                  color='primary'
                  type="submit"
                  disabled={busy}
                  loading={busy}
                >
                  {isEdit ? 'Crear' : 'Guardar'}
                </FancyButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      }
      {loading && <FancyProgress color="primary" size={100} />}
    </React.Fragment>
  );
}
