import {
  BUTTON_SAVE_ABLE,
  BUTTON_SAVE_DISABLE,
  SaveButton
} from '../types/buttonTypes';

const initialState: SaveButton = {
  buttonSave: false
}

export default function buttonSaveReducer(state = initialState, action: any) {
  switch (action.type) {
    case BUTTON_SAVE_ABLE:
      return {
        ...state,
        ...action.payload
      }
    case BUTTON_SAVE_DISABLE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  };
}
