export const SET_ORDER_DATA = 'SET_ORDER_DATA';
export const CLEAR_ORDER_DATA = 'CLEAR_ORDER_DATA';

export class Order {
  id: number = 0;
  street: string = '';
  number?: string;
  reference?: string;
  postal_code?: string;
  contact_number?: string;
  total: number = 0;
  iva_amount: number = 0;
  subtotal: number = 0;
  delivery_datetime: string = '';
  comment?: string;
  status: 'draft' | 'review' | 'approved' | 'confirmed' | 'delivered' | 'rejected' = 'draft';
  province__id: number = 0;
  province__name: string = '';
  municipality__id: number = 0;
  municipality__name: string = '';
  client: Client = new Client();
  // TODO: Add and extend generic provider model
  providers: {
    id: number;
    total: string;
    subtotal: string;
    iva_amount: string;
    provider_id: number;
    provider__name: string;
    products: OrderProduct[];
  }[] = [];
  packs: OrderPack[] = [];
}

// TODO: Add generic client model
export class Client {
  id: number = 0;
  first_name: string = '';
  last_name: string = '';
  email: string = '';
  phone?: string;
};

export interface OrderProduct {
  id: number;
  cant: number;
  total: number;
  iva_amount: number;
  subtotal: number;
  product: number;
  idx: string;
  name: string;
  price: number;
  iva_percentage: number;
  images: { id: number, image: string }[];
  company_order_detail: number;
}

export interface Product {
  id: number;
  idx: string;
  name: string;
  description: string;
  group: string;
  youtube_video: string | null;
  use_type: string;
  people_use_quantity: string | null
  company__name: string
  home_market_enabled: boolean;
  home_market_category: number
  home_market_category__name: string
  home_market_labels: { id: number, name: string, image: string }[];
  home_market_price: string;
  home_market_iva: number;
  home_market_iva__percentage: number
  home_market_iva_amount: number
  home_market_subtotal_amount: number
  images: { id: number, image: string }[];
}

export interface OrderPack {
  id: number;
  cant: number;
  total: number;
  iva_amount: number;
  subtotal: number;
  name: string;
  price: number;
  iva_percentage: number;
  order: number;
  pack_id: number;
  images: { id: number, image: string }[];
  groups: OrderGroup[];
}

export interface OrderGroup {
  id: number;
  order_pack_detail: number;
  pack_group_id: number;
  pack_group_copy_id: number;
  pack_group__name: string;
  pack_group__selection_type: 'unique' | 'complete';
  products: OrderGroupProduct[];
  selected_product: OrderGroupProduct;
}

export interface OrderGroupProduct {
  id: number;
  idx: string;
  product: number;
  name: string;
  price: string;
  iva_percentage: string;
  images: { id: number, image: string }[];
}

export interface Pack {
  id: number;
  name: string;
  price: string;
  iva__percentage: number;
  iva_amount: number;
  subtotal_amount: number;
  images: { id: number, image: string }[];
  groups: Group[];
}

export interface Group {
  id: number;
  name: string;
  selection_type: 'unique' | 'complete';
  pack: number;
  products: Product[]
}