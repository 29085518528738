import {
UserAuth,
USER_AUTHENTICATE,
USER_UNATHENTICATE,
USER_CHECK_AUTHENTICATE,
SET_USER,
AuthActionTypes,
AuthUser
} from '../types/authTypes'

export const logIn= (payload: any) =>{
  return{
    type: USER_AUTHENTICATE,
    payload: payload,
  }

}

export const logOut= () =>{
  return{
    type: USER_UNATHENTICATE
  }
}


export function checkSesion(session: UserAuth): AuthActionTypes {
    return {
      type: USER_CHECK_AUTHENTICATE,
      payload: session
    }
}

export function setUser(payload: AuthUser) {
  return {
    type: SET_USER,
    payload: payload
  }
}
