import React, { useEffect, useState } from 'react';
// Material UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Services
import { getMenus, getMenuFilters } from '../../services/MenuServices';
// Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { MenuFilters } from '../../redux/types/filtersTypes';
// App
import { FancyProgress } from '../../utils/FancyComponents';
import FancyList, { FancyItemText } from '../../utils/FancyList';
import FancyFilter, { FilterElement } from '../../utils/FancyFilter';
// Others
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) => createStyles({
  success: {
    color: theme.palette.success.main
  },
  warning: {
    color: theme.palette.warning.main
  },
  error: {
    color: theme.palette.error.main
  },
}));

const source: CancelTokenSource[] = new Array(1).fill(axios.CancelToken.source());

export default function MenusListComponent() {
  const classes = useStyles();
  const filters = useSelector((state: RootState) => state.filters);
  const [menus, setMenus] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<any[]>([]);
  const [advisors, setAdvisors] = useState<any[]>([]);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const STATUS: { [key: string]: { name: string, className: string } } = {
    L: { name: 'Pendiente localización', className: classes.warning },
    N: { name: 'Denegada localización', className: classes.error },
    C: { name: 'Captación', className: classes.warning },
    P: { name: 'Planificación', className: classes.warning },
    E: { name: 'Ejecución', className: classes.success },
    B: { name: 'Bloqueado', className: classes.error },
    F: { name: 'Finalizado', className: classes.success },
    X: { name: 'Fallido', className: classes.error },
  };
  const filterElements: FilterElement[] = [
    {
      type: 'DateRange',
      key: 'date',
      name: 'Fecha',
    },
    {
      type: 'Autocomplete',
      key: 'idev',
      name: 'Evento',
      autocompleteOptions: events.map(el => ({ value: el.IDX, name: el.EV_DENO })),
      loading: loadingFilters,
    },
    {
      type: 'Autocomplete',
      key: 'idas',
      name: 'Asesor',
      autocompleteOptions: advisors.map(el => ({ value: el.IDX, name: el.VD_DENO })),
      loading: loadingFilters,
    },
    {
      type: 'Select',
      key: 'estado',
      name: 'Estado de evento',
      selectItems: [{ value: '', name: 'Todos' }].concat(Object.keys(STATUS).map(key => ({
        value: key,
        name: STATUS[key].name
      }))),
    },
  ];

  const fetchMenus = () => {
    setLoading(true);
    const params: any = { ...filters };
    if (filters && 'date' in filters && filters.date && filters.date[0]) {
      params['dfec'] = filters.date[0].format('DDMMYYYY');
      if (filters.date[1]) {
        params['hfec'] = filters.date[1].format('DDMMYYYY');
      }
    }
    delete params.date;
    Object.keys(params).forEach(key => {
      if (!params[key]) {
        delete params[key];
      }
    });
    source[0] = axios.CancelToken.source();
    getMenus(source[0], params)
      .then(result => {
        setMenus(result);
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setMenus([]);
          setLoading(false);
        }
      });
  };

  const fetchMenusFilters = () => {
    setLoadingFilters(true);
    getMenuFilters(source[1])
      .then(result => {
        setEvents(result.eventos);
        setAdvisors(result.vendedores);
        setLoadingFilters(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoadingFilters(false);
        }
      });
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    fetchMenusFilters();
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters && filters.id === new MenuFilters().id) {
      fetchMenus();
    }
    return () => {
      source[0].cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const ExpandContent = ({ menu }: { menu: any }) => (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2"><strong>Asesor</strong></Typography>
        <Typography variant="body2">{menu.Asesor}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2"><strong>Localización</strong></Typography>
        <Typography variant="body2">{menu.Localizacion}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle2"><strong>Estado de Evento</strong></Typography>
        <Typography variant="body2">{menu['Estado de Evento']}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {
        filters && <FancyFilter
          noSearch
          elements={filterElements}
          defaultValues={new MenuFilters()}
        />
      }
      {
        !loading && <FancyList
          expand
          idKey="IdPr"
          elements={menus}
          count={menus.length}
          ListItemTextProps={(menu: any) => ({
            primary: <FancyItemText textArray={[
              menu.Denominacion,
              menu.Responsable
            ]} />,
            secondary: <FancyItemText textArray={[
              `${menu.Fecha} ${menu.Hora}`,
              menu.Evento || 'Sin evento',
            ]} />
          })}
          expandContent={(menu: any) => <ExpandContent menu={menu} />}
        />
      }
      {loading && <FancyProgress color="primary" size={100} />}
    </React.Fragment >
  );
}
