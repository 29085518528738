//v1.1
import axios from "axios";
import { logout, refreshToken } from "./AuthServices";
import { history } from "../App";
import { store } from "../index";
import { errorHandler } from "../redux/actions/ShowMessageActions";

// Defaults
axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.common['App-Key'] = process.env.REACT_APP_KEY;
axios.defaults.headers.common['Secret-Key'] = process.env.REACT_APP_SECRET_KEY;

// intercept request before is sent
axios.interceptors.request.use(
  (request) => {
    //Add authorization header if user is authenticated
    if (localStorage.getItem('token')) {
      request.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    }
    // Remove ID param added in filters if exists
    if (request.params?.id) {
      delete request.params.id;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

// intercept request response after is received
axios.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    const { dispatch } = store;
    error.isCanceled = axios.isCancel(error);
    if (!error.isCanceled && error.response) {
      // Logout user if token refresh didn't work
      if (error.config.url === 'authentication/token/refresh/') {
        logout(history, dispatch, 'Su token ha vencido, por favor vuelva a iniciar sesión');
        return Promise.reject(error);
      }

      // Return any error which is not due to authentication back to the calling service
      if (error.response.status !== 401) {
        errorHandler(error, dispatch);
        return Promise.reject(error);
      }

      // Try request again with new token
      if (localStorage.getItem('token')) {
        return refreshToken()
          .then((response) => {
            // New request with new token
            const config = error.config;
            config.headers['Authorization'] = `Bearer ${response.access}`;
            return axios.request(config);
          })
      }
    }
    if (!error.isCanceled) {
      errorHandler(error, dispatch);
    }
    return Promise.reject(error);
  }
);