import axios, { CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import { logIn, logOut, setUser } from '../redux/actions/authActions';
import { showSuccessSnackBar } from '../redux/actions/ShowMessageActions';

let calledBefore: boolean = false;
let isResolved: boolean = false;

export async function login(data: any, history: any, dispatch: Dispatch<any>, source: CancelTokenSource) {
  return axios.post('authentication/token/', data, { cancelToken: source.token })
    .then(response => {
      localStorage.setItem('token', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);
      dispatch(showSuccessSnackBar('Ha iniciado sesion exitosamente'));
      dispatch(logIn(data.username));
      history.push('/profile');
      return response.data;
    });
};

export async function logout(history: any, dispatch: Dispatch<any>, message: string) {
  try {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    calledBefore = false;
    isResolved = false;
    dispatch(showSuccessSnackBar(message));
    dispatch(logOut());
    history.push('/');
  } catch (error) {
    return Promise.reject(error);
  }
};

export async function authenticated(dispatch: Dispatch<any>): Promise<any> {
  if (!calledBefore) {
    calledBefore = true;
    return axios.get(`authentication/authenticated/`)
      .then((response => {
        dispatch(setUser({
          ...response.data,
          roles: [
            'role:read',
            'role:create',
            'role:edit',
            'role:delete',
            'user:read',
            'user:create',
            'user:edit',
            'user:delete',
            'task:read',
            'task:create',
            'task:edit',
            'task:delete',
          ]
        }));
        isResolved = true;
        return response.data;
      }))
      .catch(error => {
        if (!error.isCanceled) {
          isResolved = true;
        }
        return Promise.reject(error);
      })
  } else if (!isResolved) {
    setTimeout(() => authenticated(dispatch), 2000);
  } else {
    return Promise.resolve();
  }
}

export async function refreshToken() {
  const data = { refresh: localStorage.getItem('refresh') };
  return axios.post('authentication/token/refresh/', data)
    .then((response) => {
      localStorage.setItem('token', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);
      return response.data;
    })
    .catch(error => {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
      return Promise.reject(error);
    });
};

export async function requestChangePassword(data: any, history: any, dispatch: Dispatch<any>, source: CancelTokenSource) {
  return axios.post('authentication/recovery/', data, { cancelToken: source.token })
    .then(response => {
      dispatch(showSuccessSnackBar(`Se le ha enviado un correo a ${data.email}`));
      history.push('/restore/success');
      return response.data;
    });
}

export async function newPassword(data: any, history: any, dispatch: Dispatch<any>, source: CancelTokenSource) {
  return axios.post('authentication/restore/', data, { cancelToken: source.token })
    .then(response => {
      dispatch(showSuccessSnackBar('Su contraseña se ha cambiado de manera exitosa'));
      history.push('/');
      return response.data;
    });
}

export async function updateUserProfile(id: any, data: any, dispatch: Dispatch<any>, source: CancelTokenSource) {
  return axios.patch(`user_profile/${id}/`, data, { cancelToken: source.token })
    .then(response => {
      dispatch(showSuccessSnackBar('Se atualizó la información con éxito'));
      dispatch(setUser({
        ...response.data,
        roles: [
          'role:read',
          'role:create',
          'role:edit',
          'role:delete',
          'user:read',
          'user:create',
          'user:edit',
          'user:delete',
          'task:read',
          'task:create',
          'task:edit',
          'task:delete',
        ]
      }));
      return response.data;
    });
}