import { DateRange } from "@material-ui/pickers/DateRangePicker/RangeTypes";
import moment, { Moment } from "moment";

export const SET_FILTERS = 'SET_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';

export type FilterType = (
  null
  | UserFilters
  | GsProductFilters
  | BudgetFilters
  | CommercialActionFilters
  | EventsFilters
  | JobsFilters
  | TaskFilters
  | MenuFilters
  | TaxFilters
);

export class UserFilters {
  id: 'user' = 'user';
  search: string = '';
  is_active: string = '';
}

export class GsProductFilters {
  id: 'gs_product' = 'gs_product';
  enabled: string = '';
}

export class BudgetFilters {
  id: 'budget' = 'budget';
  date: DateRange<Moment> = [moment.utc().subtract(1, 'M'), null];
  estado: string = '0';
  tpcli: string = '0';
  dir: string = '0';
}

export class CommercialActionFilters {
  id: 'commercial_action' = 'commercial_action';
  date: DateRange<Moment> = [moment.utc(), moment.utc().add(1, 'w')];
  estado: string = '0';
  estev: string = '';
  tpcli: string = '0';
  // soloult: boolean = false;
  idev: string | null = null;
  idas: string | null = null;
  idloc: string | null = null;
  tpacc: string | null = null;
}

export class EventsFilters {
  id: 'event' = 'event';
  date: DateRange<Moment> = [moment.utc(), null];
  tipo: null | any = null;
  estado: null | any = null;
  asesor: null | any = null;
  localizacion: null | any = null;
  fallidos: boolean = false;
}

export class JobsFilters {
  id: 'job' = 'job';
  date: DateRange<Moment> = [moment.utc(), moment.utc().add(1, 'w')];
  estado: string = 'T';
  idev: string | null = null;
  dpto: string | null = null;
  resp: string | null = null;
}

export class TaskFilters {
  id: 'task' = 'task';
  date: DateRange<Moment> = [moment.utc(), moment.utc().add(1, 'w')];
  estado: string = '';
  loc: string | null = null;
  dpto: string | null = null;
  pasig: string | null = null;
  resp: string | null = null;
  idev: string | null = null;
}

export class MenuFilters {
  id: 'menu' = 'menu';
  date: DateRange<Moment> = [moment.utc(), moment.utc().add(1, 'w')];
  estado: string = '';
  idev: string | null = null;
  idas: string | null = null;
}

export class TaxFilters {
  id: 'tax' = 'tax';
  search: string = '';
}