import React from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
// App
import { FancyButton, FancyTabs, FancyTab, useFancyStyles } from '../../utils/FancyComponents';
import GeneralFormComponent from './ProfileDetails/GeneralFormComponent';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { buttonSaveAble } from '../../redux/actions/ButtonActions';
import { RootState } from '../../redux/store';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(1)
  },
  tab: {
    minWidth: 'auto',
    padding: theme.spacing(0.75),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 2),
      minWidth: 160,
    },
  },
}));

export default function ProfileDetails() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = useFancyStyles();
  const saveButton = useSelector((state: RootState) => state.saveButton.buttonSave);
  const [value, setValue] = React.useState(0);
  const tabsLabel = [
    { name: 'Información', component: GeneralFormComponent },
  ];
  const Component = tabsLabel[value].component;

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <React.Fragment>
      <AppBar position="static" className={styles.mt_2} color="default">
        <Grid container justify="space-between" alignItems='center'>
          <Grid item>
            <FancyTabs value={value} onChange={handleChange}>
              {
                tabsLabel.map((tab: any, index: any) => (
                  <FancyTab {...a11yProps(index)} key={index} label={tab.name} className={classes.tab} />
                ))
              }
            </FancyTabs>
          </Grid>
          <Grid item>
            <Hidden xsDown>
              <FancyButton
                color="primary"
                variant="contained"
                disabled={saveButton}
                loading={saveButton}
                className={classes.submitButton}
                onClick={() => dispatch(buttonSaveAble())}
              >
                Guardar cambios
              </FancyButton>
            </Hidden>
            <Hidden smUp>
              <IconButton
                type="button"
                color="primary"
                onClick={() => dispatch(buttonSaveAble())}
                disabled={saveButton}
              >
                <SaveIcon />
              </IconButton>
            </Hidden>
          </Grid>
        </Grid>
      </AppBar>
      <Box p={2}>
        <Component />
      </Box>
    </React.Fragment >
  );
}

