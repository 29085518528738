import React, { useEffect, useState } from 'react';
// Material UI
import { useTheme } from '@material-ui/core/styles';
import { purple, deepOrange } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Services
import { getEvents } from '../../services/EventsServices';
// Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { EventsFilters } from '../../redux/types/filtersTypes';
// App
import { FancyProgress } from '../../utils/FancyComponents';
import FancyList, { FancyItemText } from '../../utils/FancyList';
import FancyFilter, { FilterElement } from '../../utils/FancyFilter';
// Others
import moment from 'moment';
import axios, { CancelTokenSource } from 'axios';

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function EventListComponent() {
  const theme = useTheme();
  const filters = useSelector((state: RootState) => state.filters);
  const [events, setEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [advisers, setAdvisers] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<{ [key: string]: string }>({});
  const [types, setTypes] = useState<string[]>([]);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const filterElements: FilterElement[] = [
    {
      type: 'DateRange',
      key: 'date',
      name: 'Fecha',
    },
    {
      type: 'Autocomplete',
      key: 'tipo',
      name: 'Tipo de evento',
      autocompleteOptions: types,
      loading: loadingFilters,
    },
    {
      type: 'Autocomplete',
      key: 'estado',
      name: 'Estado de Evento',
      autocompleteOptions: Object.keys(statuses).map(key => ({ value: key, name: statuses[key] })),
      loading: loadingFilters,
    },
    {
      type: 'Autocomplete',
      key: 'asesor',
      name: 'Asesor',
      autocompleteOptions: advisers,
      loading: loadingFilters,
    },
    {
      type: 'Autocomplete',
      key: 'localizacion',
      name: 'Locación',
      autocompleteOptions: locations,
      loading: loadingFilters,
    },
    {
      type: 'Checkbox',
      key: 'fallidos',
      name: 'Estado',
      label: '¿Fallido?',
    },
  ];

  const fetchEvents = () => {
    setLoading(true);
    const params: any = { ...filters };
    if (filters && 'date' in filters && filters.date && filters.date[0]) {
      params['dfec'] = filters.date[0].format('DDMMYYYY');
      if (filters.date[1]) {
        params['hfec'] = filters.date[1].format('DDMMYYYY');
      }
    }
    delete params.date;
    Object.keys(params).forEach(key => {
      if (params[key] === '') {
        delete params[key];
      }
    })
    source[0] = axios.CancelToken.source();
    getEvents(source[0], params)
      .then(result => {
        setEvents(result.EVENTOS);
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setEvents([])
          setLoading(false);
        }
      });
  };

  const fetchFilters = () => {
    setLoadingFilters(true);
    getEvents(source[1])
      .then(result => {
        setAdvisers(Object.values(result.ASESORES));
        setLocations(Object.values(result.LOCALIZACIONES));
        setTypes(Object.values(result.TIPOS));
        setStatuses(result.ESTADOS);
        setLoadingFilters(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setLoadingFilters(false);
        }
      });
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    fetchFilters();
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters && filters.id === new EventsFilters().id) {
      fetchEvents();
    }
    return () => {
      source[0].cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const getColor = (state: string) => {
    switch (state) {
      case 'A':
        return theme.palette.warning.main;
      case 'P':
        return theme.palette.success.main;
      case 'C':
      case 'L':
        return deepOrange[500];
      case 'E':
        return theme.palette.info.main;
      case 'F':
        return purple[500];
      case 'B':
        return theme.palette.grey[500];
      case 'X':
      case 'N':
        return theme.palette.error.main;
      default:
        return '';
    }
  };

  const ExpandContent = ({ event }: { event: any }) => (
    <Grid container spacing={1}>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Hora</strong></Typography>
        <Typography variant="body2">{event.HORA}</Typography>
      </Grid>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Ciudad</strong></Typography>
        <Typography variant="body2">{event.LUGAR}</Typography>
      </Grid>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Tipo de evento</strong></Typography>
        <Typography variant="body2">{event.TIPO ? event.TIPO.NOMBRE : 'No ingresado'}</Typography>
      </Grid>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Cliente</strong></Typography>
        <Typography variant="body2">{event.CLIENTE ? event.CLIENTE.NOMBRE : 'No ingresado'}</Typography>
      </Grid>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Asesor</strong></Typography>
        <Typography variant="body2">{event.ASESOR ? event.ASESOR.NOMBRE : 'No ingresado'}</Typography>
      </Grid>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Comisionable</strong></Typography>
        <Typography variant="body2">{event.COMISIONABLE === 'S' ? 'Si' : 'No'}</Typography>
      </Grid>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Número de invitados</strong></Typography>
        <Typography variant="body2">{event.N_INVITADOS}</Typography>
      </Grid>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Selección de materiales</strong></Typography>
        <Typography variant="body2">
          {event.SEL_MATERIAL && event.SEL_MATERIAL.NOMBRE ? event.SEL_MATERIAL.NOMBRE : 'Sin materiales'}
        </Typography>
      </Grid>
      <Grid item sm={4} xs={6}>
        <Typography variant="subtitle2"><strong>Pruebas de menu</strong></Typography>
        <Typography variant="body2">
          {event.PRUEBAS_MENU && event.PRUEBAS_MENU.length > 0 ? event.PRUEBAS_MENU[0].DENO_ESTADO : 'Sin pruebas'}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {
        filters && <FancyFilter
          noSearch
          elements={filterElements}
          defaultValues={new EventsFilters()}
        />
      }
      {
        !loading && <FancyList
          expand
          idKey="IDX"
          elements={events}
          count={events.length}
          ListItemTextProps={(event: any) => ({
            primary: `${event.EVENTO}`,
            secondary: <FancyItemText textArray={[
              moment.utc(event.FECHA, 'DDMMYYYY').format('DD/MM/YYYY'),
              event.LOCALIZACION && event.LOCALIZACION.NOMBRE,
              <Typography component="span" style={{ color: getColor(event.ESTADO) }}>
                {statuses[event.ESTADO]}
              </Typography>
            ]} />
          })}
          expandContent={(event: any) => <ExpandContent event={event} />}
        />
      }
      {loading && <FancyProgress color="primary" size={100} />}
    </React.Fragment >
  );
}
