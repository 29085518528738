import React from 'react';
// Material UI
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
// Redux
// import { useSelector } from 'react-redux';
// import { RootState } from './redux/store';
// App
import { Theme } from './utils/Theme';
import Routing from './router/Routing';
import ShowSnackbar from './components/ShowSnackbar';
import './services/axios-config';
// Others
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';

export const history = createBrowserHistory();

export default function App() {
  // const data = useSelector((state: RootState) => state.filters);
  // console.log(data);

  return (
    <ThemeProvider theme={Theme}>
      <Router history={history}>
        <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale="es">
          <CssBaseline />
          <Routing />
          <ShowSnackbar />
        </LocalizationProvider>
      </Router>
    </ThemeProvider>
  );
}