import axios, { CancelTokenSource } from 'axios';

export async function getMenus(source: CancelTokenSource, params?: any) {
  return axios.get(`gs/menu/`, { params: params, cancelToken: source.token })
    .then(response => response.data);
};

export async function getMenuFilters(source: CancelTokenSource, params?: any) {
  return axios.get(`gs/filters/menu/`, { params: params, cancelToken: source.token })
    .then(response => response.data);
};
