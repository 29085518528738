import {
  USER_AUTHENTICATE,
  USER_UNATHENTICATE,
  SET_USER,
  UserAuth,
  AuthUser,
} from '../types/authTypes'

const initialState: UserAuth = {
  username: '',
  loggeIn: false,
  user: new AuthUser(),
}

export default function authReducer(state = initialState, action: any): UserAuth {
  switch (action.type) {
    case USER_AUTHENTICATE:
      return {
        ...state,
        username: action.payload,
        loggeIn: true,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case USER_UNATHENTICATE:
      return initialState;
    default:
      return state;
  }
}
