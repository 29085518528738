//v1.2
import React, { forwardRef, FunctionComponent } from 'react';
// Material ui
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Box, { BoxProps } from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab, { TabProps } from '@material-ui/core/Tab';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Button, { ButtonProps } from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// App
import ImageWithIcon from './ImageWithIcon';

export const useFancyStyles = makeStyles((theme: Theme) =>
  createStyles({
    h_100: {
      height: '100% !important',
    },
    m_2: {
      margin: theme.spacing(2) + 'px !important',
    },
    m_05: {
      margin: theme.spacing(0.5) + 'px !important',
    },
    mt_1: {
      marginTop: theme.spacing(1) + 'px !important',
    },
    mt_2: {
      marginTop: theme.spacing(2) + 'px !important',
    },
    mb_1: {
      marginBottom: theme.spacing(1) + 'px !important',
    },
    mb_2: {
      marginBottom: theme.spacing(2) + 'px !important',
    },
    mr_1: {
      marginRight: theme.spacing(1) + 'px !important',
    },
    p_1: {
      padding: theme.spacing(1) + 'px !important',
    },
    p_2: {
      padding: theme.spacing(2) + 'px !important',
    },
    pb_0: {
      paddingBottom: 0 + ' !important',
    },
    pt_0: {
      paddingTop: 0 + ' !important',
    },
    pointer: {
      cursor: 'pointer !important',
    },
    circularRadius: {
      borderRadius: 30,
    },
    primaryHover: {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      }
    },
    secondaryHover: {
      color: theme.palette.secondary.main,
      '&:hover': {
        color: theme.palette.secondary.dark,
      }
    },
    modalTitle: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeModalButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
    tooltipArrow: {
      color: theme.palette.common.black,
    },
    button: {
      borderRadius: 30,
      margin: theme.spacing(1, 0),
    },
    buttonWrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: theme.palette.success.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    centeredImageBackground: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    thinnerScroll: {
      '&::-webkit-scrollbar': {
        width: theme.spacing(0.8),
      },
    }
  }),
);

export const FancyTextInput = forwardRef<any, TextFieldProps>((props, ref) => (
  <TextField
    {...props}
    ref={ref}
    fullWidth
    margin="dense"
    variant="outlined"
  />
));

export const PasswordTextInput = forwardRef<any, TextFieldProps & { withoutIcon?: boolean }>((props, ref) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const { withoutIcon, ...TextInputProps } = props;
  return (
    <FancyTextInput
      {...TextInputProps}
      ref={ref}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        startAdornment: !withoutIcon && <InputAdornment position="start"><LockIcon color="secondary" /></InputAdornment>,
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(prev => !prev)}
              onMouseDown={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => event.preventDefault()}
            >
              {!showPassword ? <VisibilityIcon color="secondary" /> : <VisibilityOffIcon color="secondary" />}
            </IconButton>
          </InputAdornment>
      }} />
  )
});

export const FancyButton = forwardRef(<C extends React.ElementType>(props: (ButtonProps<C, { component?: C }> & { loading?: boolean }), ref: any) => {
  const classes = useFancyStyles();
  const { loading, ...buttonProps } = props;
  return (
    <div className={classes.buttonWrapper}>
      <Button {...buttonProps} ref={ref} className={[classes.button, buttonProps.className].join(' ')} />
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  )
});

export const FancyImage = (props: BoxProps & { image: string, position?: string }) => {
  const classes = useFancyStyles();
  const { image, position, ...boxProps } = props;
  return <Box
    {...boxProps}
    className={[classes.centeredImageBackground, props.className].join(' ')}
    style={{ backgroundImage: `url(${image})`, backgroundPosition: position || 'center' }}
  />
};

export interface Size {
  w: number;
  h: number;
}
interface ImageInputProps {
  image: string | null;
  // alt: string;
  onChange(files: FileList | null): void;
  name?: string;
  useIcon?: boolean;
  buttonText?: string;
  rounded?: boolean;
  xs?: Size;
  sm?: Size;
  md?: Size;
  lg?: Size;
  xl?: Size;
};
const xsDefault: Size = { w: 150, h: 150 };
const smDefault: Size = { w: 150, h: 150 };
const mdDefault: Size = { w: 200, h: 200 };
const lgDefault: Size = { w: 200, h: 200 };
const xlDefault: Size = { w: 300, h: 300 };
export const ImageInput = (props: ImageInputProps) => {
  const { image, onChange, useIcon, buttonText, name, ...imageWithIconProps } = props;
  const xs = imageWithIconProps.xs || xsDefault;
  const sm = imageWithIconProps.sm || smDefault;
  const md = imageWithIconProps.md || mdDefault;
  const lg = imageWithIconProps.lg || lgDefault;
  const xl = imageWithIconProps.xl || xlDefault;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      ImageInputIcon: {
        backgroundColor: theme.palette.grey[200],
        borderRadius: imageWithIconProps.rounded ? '50%' : 0,
        width: xl.w,
        height: xl.h,
        [theme.breakpoints.down('lg')]: {
          width: lg.w,
          height: lg.h,
        },
        [theme.breakpoints.down('md')]: {
          width: md.w,
          height: md.h,
        },
        [theme.breakpoints.down('sm')]: {
          width: sm.w,
          height: sm.h,
        },
        [theme.breakpoints.down('xs')]: {
          width: xs.w,
          height: xs.h,
        },
      }
    }),
  );
  const styles = useFancyStyles();
  const classes = useStyles();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.files);
    event.currentTarget.value = '';
  }

  return (
    <Grid container justify="center" alignItems="center" spacing={1}>
      <input
        type="file"
        accept="image/*"
        id={name || 'contained-button-file'}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      {
        !image ? (useIcon ? <Grid item className={classes.ImageInputIcon}>
          <Typography component="label" htmlFor={name || 'contained-button-file'} align="center" className={styles.pointer}>
            <Grid container justify="center" alignItems="center" className={styles.h_100}>
              <Grid item>
                <AddCircleOutlineIcon color="primary" style={{ fontSize: 60 }} />
              </Grid>
            </Grid>
          </Typography>
        </Grid> : <Grid item>
            <Typography component="label" htmlFor={name || 'contained-button-file'} align="center">
              <FancyButton variant="contained" color="secondary" component="span">
                {buttonText || 'Subir Imagen'}
              </FancyButton>
            </Typography>
          </Grid>) : <Grid item>
            <Typography component="label" htmlFor={name || 'contained-button-file'}>
              <ImageWithIcon {...imageWithIconProps} image={image} />
            </Typography>
          </Grid>
      }
    </Grid>
  );
}

export const FancyModal = (props: DialogProps) => {
  const { onClose, children } = props;
  const classes = useFancyStyles();

  return (
    <Dialog {...props}>
      {onClose ? (
        <DialogTitle disableTypography className={classes.modalTitle}>
          <IconButton
            size="small"
            className={classes.closeModalButton}
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClose(event, 'backdropClick')}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      ) : null}
      {children}
    </Dialog>
  )
};

export const FancyTooltip = (props: TooltipProps) => {
  const classes = useFancyStyles();

  return <Tooltip arrow classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }} {...props} />;
}

export const FancyProgress = (props: CircularProgressProps & { containerClass?: string }) => {
  const classes = useFancyStyles();
  const { containerClass, ...progressProps } = props;
  return (
    <Grid container justify="center" alignItems="center" className={containerClass}>
      <Grid item className={classes.m_2}>
        <CircularProgress {...progressProps} />
      </Grid>
    </Grid>
  )
};

export const FancyTabs = withStyles((theme: Theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

export const FancyTab = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 'auto !important',
    marginRight: theme.spacing(2),
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}))((props: TabProps) => <Tab disableRipple {...props} />);

export const FancyDiv: FunctionComponent<{ color: string }> = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      getColor: (props: { color: string }) => ({
        backgroundColor: props.color,
        color: theme.palette.getContrastText(props.color),
        padding: theme.spacing(0.5, 1),
      })
    }),
  );
  const classes = useStyles({ color: props.color });

  return <div className={classes.getColor}>
    <Typography>{props.children}</Typography>
  </div>
};