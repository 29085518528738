import {
  SHOW_DIALOG,
  DISMISS_DIALOG,
  SHOW_DIALOG_EDIT,
  DISMISS_DIALOG_EDIT,
  SHOW_DIALOG_FILTER,
  DISMISS_DIALOG_FILTER,
  Dialog
} from '../types/dialogTypes';

const initialState: Dialog = {
  open: false,
  openEdit: false,
  openFilter: false,
}

export default function dialogReducer(state = initialState, action: any) {
  switch (action.type) {
    case SHOW_DIALOG:
      return {
        ...state,
        ...action.payload
      }
    case DISMISS_DIALOG:
      return {
        ...state,
        ...action.payload
      }
    case SHOW_DIALOG_EDIT:
      return {
        ...state,
        ...action.payload
      }
    case DISMISS_DIALOG_EDIT:
      return {
        ...state,
        ...action.payload
      }
    case SHOW_DIALOG_FILTER:
      return {
        ...state,
        ...action.payload
      }
    case DISMISS_DIALOG_FILTER:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  };

}