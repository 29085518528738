import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import LoginComponent from '../../components/LoginComponent';
import Background from '../../assets/media/images/login_background.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
  },
  img: {
    width: '100%',
    height: '100%',
  },
  h_100: {
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
    }
  }
}));


export default function LogInViews() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid
      container
      direction={matchesDesktop ? 'row' : 'column-reverse'}
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs sm={7} container justify="center" alignItems="center" className={classes.h_100}>
        <img src={Background} alt="login" className={classes.img} />
      </Grid>
      <Grid item xs sm={5} container justify="center" alignItems="center">
        <LoginComponent />
      </Grid>
    </Grid>
  );
}