//v1.4
import React, { ReactElement } from 'react';
// Material UI
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List, { ListProps } from '@material-ui/core/List';
import ListItem/*, { ListItemProps }*/ from '@material-ui/core/ListItem';
import ListItemIcon, { ListItemIconProps } from '@material-ui/core/ListItemIcon';
import ListItemText, { ListItemTextProps } from '@material-ui/core/ListItemText';
import ListItemAvatar, { ListItemAvatarProps } from '@material-ui/core/ListItemAvatar';
import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import ListItemSecondaryAction, { ListItemSecondaryActionProps } from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Menu from '@material-ui/core/Menu';
import MenuItem/*, { MenuItemProps }*/ from '@material-ui/core/MenuItem';
import IconButton/*, { IconButtonProps }*/ from '@material-ui/core/IconButton';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// App
import { FancyProgress, useFancyStyles } from './FancyComponents';
// Other
import { Virtuoso, ListProps as VirtuosoListProps } from 'react-virtuoso'

export interface ActionButton<T = any> {
  icon: ReactElement; // IconButton (desktop) or MenuItem (mobile) Icon element
  text: string | ((element: T) => string); // Text showed on the MenuItem (mobile)
  onClick: (event: any, element: T) => void; // IconButton (desktop) or MenuItem (mobile) click handler
  disabled?: boolean | ((element: T) => boolean); // if IconButton (desktop) or MenuItem (mobile) is disabled
  show?: boolean | ((element: T) => boolean); // if false element will not be showed
  IconButtonProps?: any; // props passed to IconButton component (desktop)
  // IconButtonProps?: IconButtonProps | ((element: T) => IconButtonProps); // props passed to IconButton component (desktop)
  MenuItemProps?: any; // props passed to MenuItem component (mobile)
  // MenuItemProps?: MenuItemProps | ((element: T) => MenuItemProps); // props passed to MenuItem component (mobile)
  menuOnly?: boolean; // if true element will be showed only in Menu
};

interface FancyLisGeneralProps<T = any> {
  elements: T[]; // array of elements to iterate inside List
  count?: number | string; // total elements count, if not provides total count ListItem is not showed
  idKey?: string; // Unique key used to differentiate elementes
  disablePadding?: boolean; // if true list padding will be set to 0
  emptyMessage?: string; // message to be showed when there aren´t any elements
  divider?: boolean; // if true a Divider will be added at the end of each element
  actionButtons?: ActionButton<T>[]; // array of actions to iterate in ListItemSecondaryAction (desktop) or Menu (mobie)
  ListProps?: ListProps<'div'>; // props passed to List component
  ListItemProps?: any; // props passed to ListItem component
  // ListItemProps?: ListItemProps<'li'> | ((element: T) => ListItemProps<'li'>); // props passed to ListItem component
  ListItemTextProps?: ListItemTextProps | ((element: T) => ListItemTextProps); // props passed to ListItemText component
  ListItemIconProps?: ListItemIconProps | ((element: T) => ListItemIconProps); // props passed to ListItemIcon component
  ListItemSecondaryActionProps?: ListItemSecondaryActionProps | ((element: T) => ListItemSecondaryActionProps); // props passed to ListItemSecondaryAction component
  ListItemAvatarProps?: Omit<ListItemAvatarProps, 'children'> | ((element: T) => Omit<ListItemAvatarProps, 'children'>); // props passed to ListItemAvatar component
  AvatarProps?: AvatarProps | ((element: T) => AvatarProps); // props passed to Avatar component
};
type FancyListInfiniteProps<T = any> = { infinite?: false } | {
  infinite: true; // if true InfiniteScroll would be used
  fetchElements: () => Promise<void>; // callback passed to InfiniteScroll to fetch more elements
  hasMore: boolean; // condition to check if InfiniteScroll should fetch more elements
}
type FancyListCheckProps<T = any> = { check?: false } | {
  check: true; // // if true List would have Checkbox to select elements
  checked: number[]; // array of checked elements ids
  checkboxOnChange: (element: T) => void; // event fired when a checkbox value changes
  CheckboxProps?: CheckboxProps | ((element: T) => CheckboxProps); // props passed to Checkbox component
}
type FancyListExpandProps<T = any> = { expand?: false } | {
  expand: true; // if true List would have Accordion to expand content
  expandContent: (element: T) => (ReactElement | null) // content showed inside AccordionDetails component whenever an element is expanded
  onAccordionChange?: (isExpanded: boolean, element: T) => void; // handler called whenever an element is expanded
}
type FancyListRecursiveProps<T = any> = { recursive?: false } | {
  recursive: true; // if true there will be a Collapse to show more ListItem inside each other, not recommended with expand
  expandedElements: number[]; // array of elements ids who are expanded in recursive mode
  hasChildren: (element: T) => boolean; // condition to determine if Collapse will be show in a given element
  onRecursiveClick: (event: any, element: T) => void; // callback called whenever an element is expanded
  recursiveChildren: (element: T, ListItem: (ListProps: { // content showed inside Collapse
    element: T;
    level: number;
  }) => JSX.Element, ListItemProps: { level: number }) => ReactElement;
}
type FancyListProps<T = any> = (
  FancyLisGeneralProps<T>
  & FancyListInfiniteProps<T>
  & FancyListCheckProps<T>
  & FancyListExpandProps<T>
  & FancyListRecursiveProps<T>
);

interface FancyActionButtonProps<T = any> {
  onClick?: (event: any) => void; // IconButton click handler called to open menu
  onClose?: () => void; // callback called when menu is closed
  disabled?: boolean; // if IconButton is disabled
  icon?: ReactElement; // IconButton Icon element, default is MoreVert
  element: T; // current element of Menu
  actions: ActionButton<T>[]; // array of actions to iterate in Menu
};

interface FancyItemTextProps {
  textArray: Array<string | ReactElement | undefined>;
  TypographyProps?: TypographyProps;
}
export const FancyItemText = (props: FancyItemTextProps) => {
  const textArray = props.textArray.filter(text => text);

  return (
    <React.Fragment>
      <Hidden xsDown>
        <Typography component="span" display="block" {...props.TypographyProps}>
          {textArray.map((text, index) => <React.Fragment key={index}>
            {text} {index !== textArray.length - 1 && '| '}
          </React.Fragment>)}
        </Typography>
      </Hidden>

      <Hidden smUp>
        {
          textArray.map((text, index) => (
            <Typography component="span" display="block" key={index} {...props.TypographyProps}>
              {text}
            </Typography>
          ))
        }
      </Hidden>
    </React.Fragment>
  )
};

const FancyActionButton = <T extends any>(props: FancyActionButtonProps<T>) => {
  const classes = useFancyStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const menuClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (props.onClick) {
      props.onClick(event);
    }
  };

  const menuCloseHandler = () => {
    setAnchorEl(null);
    if (props.onClose) {
      props.onClose();
    }
  };

  const isObject = <P extends {}>(element: P | ((element: T) => P)): element is P => {
    return typeof (element as P) === 'object';
  }

  const spreadProps = <P extends {}>(element: T, propsToSpread?: P | ((element: T) => P)): P | undefined => {
    return propsToSpread && (isObject(propsToSpread) ? propsToSpread : propsToSpread(element));
  };

  return <React.Fragment>
    <Hidden xsDown>
      {
        props.actions.filter(button => !button.menuOnly).map((button, index) => {
          const length = props.actions ? props.actions.length : 0;
          const show = button.show === undefined || (typeof button.show === 'boolean' ? button.show : button.show(props.element));
          return show && <IconButton
            key={index}
            color="primary"
            onClick={(event: any) => button.onClick(event, props.element)}
            disabled={button.disabled && (typeof button.disabled === 'boolean' ? button.disabled : button.disabled(props.element))}
            className={(index !== length - 1) ? classes.mr_1 : undefined}
            size="medium"
            {...spreadProps(props.element, button.IconButtonProps)}
          >
            {button.icon}
          </IconButton>
        })
      }
    </Hidden>
    <Hidden smUp>
      <IconButton
        color="primary"
        onClick={(event) => menuClickHandler(event)}
        disabled={props.disabled}
        size="small"
      >
        {props.icon ? props.icon : <MoreVertIcon />}
      </IconButton>
    </Hidden>
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={menuCloseHandler}
    >
      {
        props.actions.map((action, index) => {
          const show = action.show === undefined || (typeof action.show === 'boolean' ? action.show : action.show(props.element));
          return show && <MenuItem
            {...spreadProps(props.element, action.MenuItemProps)}
            key={index}
            button={true}
            disabled={action.disabled && (typeof action.disabled === 'boolean' ? action.disabled : action.disabled(props.element))}
            onClick={(event: any) => {
              action.onClick(event, props.element)
              menuCloseHandler();
            }}
          >
            <ListItemIcon>{action.icon}</ListItemIcon>
            <ListItemText primary={typeof action.text === 'string' ? action.text : action.text(props.element)} />
          </MenuItem>
        })
      }
    </Menu>
  </React.Fragment>;
};

export default function FancyList<T = unknown>(props: FancyListProps<T>) {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        height: 'calc(100vh - 70px) !important',
        [theme.breakpoints.down('xs')]: {
          height: 'calc(100vh - 60px) !important',
        }
      },
      list: {
        marginBottom: 50
      },
      listItem: {
        width: '100%',
      },
      listItemIcon: {
        [theme.breakpoints.down('xs')]: {
          minWidth: 40,
        }
      },
      checkbox: {
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(0.5),
        }
      },
      listItem_secondaryAction: {
        paddingRight: (30 + theme.spacing(1)) * (props.recursive ? 2 : 1),
        paddingLeft: !!props.expand ? 0 : 8,
        [theme.breakpoints.up('sm')]: {
          paddingLeft: !!props.expand ? 0 : 16,
          paddingRight: (48 + theme.spacing(1)) * ((props.actionButtons ? props.actionButtons.filter(button => !button.menuOnly).length : 0) + (props.recursive ? 1 : 0))
        },
      },
      listItemSecondaryAction: {
        right: theme.spacing(1),
      },
      accordionSummary: {
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(0, 1),
        }
      },
    })
  );
  const classes = useStyles();
  const styles = useFancyStyles();
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [expanded, setExpanded] = React.useState<number | false>(false);

  const getId = (element: any) => props.idKey ? element[props.idKey] : element.id;

  const checkboxToggleHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, element: T) => {
    event.stopPropagation();
    if (props.check) {
      props.checkboxOnChange(element);
    }
  };

  const accordionChangeHandler = (element: T) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? getId(element) : false);
    if (props.expand && props.onAccordionChange) {
      props.onAccordionChange(isExpanded, element);
    }
  };

  const listItemClickHandler = (event: any, element: T) => {
    if (props.recursive) {
      props.onRecursiveClick(event, element);
    }
    const ListItemProps = spreadProps(element, props.ListItemProps);
    if (ListItemProps && ListItemProps.onClick) {
      ListItemProps.onClick(event);
    }
  };

  const listItemIconClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, element: T) => {
    event.stopPropagation();
    const listItemIconProps = spreadProps(element, props.ListItemIconProps)?.onClick;
    if (listItemIconProps) {
      listItemIconProps(event);
    }
  };

  // Callback to fetch more elements
  const loadMoreElements = React.useCallback(() => {
    return (props.infinite && props.hasMore) ? props.fetchElements() : (() => null);
    // eslint-disable-next-line
  }, [props.elements]);

  const isObject = <P extends {}>(element: P | ((element: T) => P)): element is P => {
    return typeof (element as P) === 'object';
  }

  const spreadProps = <P extends {}>(element: T, propsToSpread?: P | ((element: T) => P)): P | undefined => {
    return propsToSpread && (isObject(propsToSpread) ? propsToSpread : propsToSpread(element));
  };

  const FancyListItem = (ListProps: { element: T, level: number }) => {
    const level = ListProps.level - 1;
    const maxLevel = matchesDesktop ? 5 : 3;
    const increment = level > maxLevel ? maxLevel : level;
    const spacing = matchesDesktop ? 4 : 2;
    const leftPadding = matchesDesktop ? 2 : 1;
    return <React.Fragment>
      <ListItem
        button={!!props.recursive}
        ContainerComponent="div"
        {...spreadProps(ListProps.element, props.ListItemProps)}
        style={{ ...spreadProps(ListProps.element, props.ListItemProps)?.style, paddingLeft: theme.spacing((increment * spacing) + leftPadding) }}
        classes={{
          secondaryAction: [classes.listItem_secondaryAction, spreadProps(ListProps.element, props.ListItemProps)?.classes?.secondaryAction].join(' '),
          container: [classes.listItem, spreadProps(ListProps.element, props.ListItemProps)?.classes?.container].join(' '),
        }}
        onClick={(event: any) => listItemClickHandler(event, ListProps.element)}
      >
        {
          props.check &&
          <ListItemIcon
            {...spreadProps(ListProps.element, props.ListItemIconProps)}
            className={[classes.listItemIcon, spreadProps(ListProps.element, props.ListItemIconProps)?.className].join(' ')}
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => listItemIconClickHandler(event, ListProps.element)}
          >
            <Checkbox
              color="primary"
              size={matchesDesktop ? 'medium' : 'small'}
              checked={props.checked && props.checked.indexOf(getId(ListProps.element)) !== -1}
              {...spreadProps(ListProps.element, props.CheckboxProps)}
              className={[classes.checkbox, spreadProps(ListProps.element, props.CheckboxProps)?.className].join(' ')}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => checkboxToggleHandler(event, ListProps.element)}
            />
          </ListItemIcon>
        }
        {
          props.ListItemIconProps && !props.check &&
          <ListItemIcon {...spreadProps(ListProps.element, props.ListItemIconProps)} />
        }
        {
          (props.AvatarProps) &&
          <ListItemAvatar {...spreadProps(ListProps.element, props.ListItemAvatarProps)}>
            <Avatar {...spreadProps(ListProps.element, props.AvatarProps)} />
          </ListItemAvatar>
        }
        <ListItemText {...spreadProps(ListProps.element, props.ListItemTextProps)} />
        {
          (props.actionButtons || props.recursive) &&
          <ListItemSecondaryAction
            {...spreadProps(ListProps.element, props.ListItemSecondaryActionProps)}
            className={[classes.listItemSecondaryAction, spreadProps(ListProps.element, props.ListItemSecondaryActionProps)?.className].join(' ')}
          >
            {
              props.actionButtons && props.actionButtons.filter(button => button.show === undefined || (typeof button.show === 'boolean' ? button.show : button.show(ListProps.element))).length > 0 &&
              <FancyActionButton actions={props.actionButtons} element={ListProps.element} />
            }
            {
              props.recursive && props.hasChildren(ListProps.element) &&
              <IconButton
                color="primary"
                onClick={(event) => props.onRecursiveClick && props.onRecursiveClick(event, ListProps.element)}
                size={matchesDesktop ? 'medium' : 'small'}
              >
                {
                  props.expandedElements && props.expandedElements.includes(getId(ListProps.element))
                    ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
              </IconButton>
            }
          </ListItemSecondaryAction>
        }
      </ListItem>
      {
        props.recursive && <React.Fragment>
          <Divider style={{ marginLeft: theme.spacing(increment * spacing) }} />
          {
            props.hasChildren && props.hasChildren(ListProps.element) &&
            <Collapse
              in={props.expandedElements && props.expandedElements.includes(getId(ListProps.element))}
              timeout="auto"
            >
              {
                props.recursiveChildren && props.recursiveChildren(ListProps.element, FancyListItem, { level: ListProps.level + 1 })
              }
            </Collapse>
          }
        </React.Fragment>
      }
    </React.Fragment>
  };

  const ListContent = ({ element, index }: { element: T, index: number }) => {
    return <React.Fragment>
      {
        !props.recursive && !!props.expand ?
          <Accordion
            key={getId(element)}
            expanded={expanded === getId(element)}
            onChange={accordionChangeHandler(element)}
            TransitionProps={{ unmountOnExit: true }}
            variant="outlined"
          >
            <AccordionSummary
              expandIcon={matchesDesktop ? <ExpandMoreIcon color="primary" /> : null}
              IconButtonProps={{ size: matchesDesktop ? 'medium' : 'small' }}
              className={classes.accordionSummary}
            >
              <FancyListItem element={element} level={1} />
            </AccordionSummary>
            {
              props.expandContent &&
              <AccordionDetails>
                {props.expandContent(element)}
              </AccordionDetails>
            }
          </Accordion> : <React.Fragment key={getId(element)}>
            <FancyListItem element={element} level={1} />
            {props.divider && (index !== props.elements.length - 1) && <Divider />}
          </React.Fragment>
      }
    </React.Fragment>
  };

  // Components passed to Virtuoso
  const VirtuosoComponents = {
    List: React.forwardRef<HTMLDivElement, VirtuosoListProps>(({ style, children }, listRef) => {
      return (
        <List
          {...props.ListProps}
          className={[!props.disablePadding ? classes.list : '', props.ListProps?.className].join(' ')}
          style={{ padding: 0, ...style, margin: 0, ...props.ListProps?.style }}
          component="div"
          ref={listRef}
        >
          {children}
        </List>
      );
    }),
    Header: (props.elements.length > 0 && !!props.count) ? (headerProps: any) => {
      return (<Typography {...headerProps} variant="h6" style={{ fontWeight: 'bold' }}>
        {props.count} RESULTADO{props.count === 1 ? '' : 'S'}
      </Typography>);
    } : undefined,
    EmptyPlaceholder: (placeholderProps: any) => {
      return (<Typography {...placeholderProps} variant="h6">
        {props.emptyMessage || 'No se encontraron resultados'}
      </Typography>);
    },
    Footer: (props.infinite && props.hasMore) ? () => {
      return (<FancyProgress color="primary" size={50} />)
    } : undefined,
  };

  return (
    <Virtuoso
      useWindowScroll
      className={[styles.thinnerScroll].join(' ')}
      // className={[styles.thinnerScroll, classes.container].join(' ')}
      data={props.elements}
      endReached={loadMoreElements}
      components={VirtuosoComponents}
      itemContent={(index, element) => <ListContent index={index} element={element} />}
    />
  );
}