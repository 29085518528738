import React, { useEffect, useState } from 'react';
// Material UI
import DialogContent from '@material-ui/core/DialogContent';
// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
// Services
import { getTaxes } from '../../services/TaxServices';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { TaxFilters } from '../../redux/types/filtersTypes';
import { showDialogEdit, dismissDialogEdit, dismissDialog, /*showDialog*/ } from '../../redux/actions/DialogActions'
// App
import { FancyModal, FancyProgress } from '../../utils/FancyComponents';
import FancyList, { ActionButton } from '../../utils/FancyList';
import FancyFilter from '../../utils/FancyFilter';
import TaxFormComponent from './TaxFormComponent';
// Others
import axios, { CancelTokenSource } from 'axios';

// const useStyles = makeStyles((theme: Theme) => createStyles({
//   button: {
//     position: 'fixed',
//     bottom: '3%',
//     right: '3%'
//   },
// }));

const source: CancelTokenSource[] = new Array(2).fill(axios.CancelToken.source());

export default function TaxListComponent() {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const dialog = useSelector((state: RootState) => state.dialog);
  const filters = useSelector((state: RootState) => state.filters);
  const [taxes, setTaxes] = useState([]);
  const [selectedTax, setSelectedTax] = useState<any | null>(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const limit = 30;
  const actionButtons: ActionButton[] = [
    {
      text: 'Editar',
      icon: <EditIcon />,
      onClick: (event: any, tax: any) => handleOptions('edit', tax, event),
    },
  ];

  async function fetchTaxes(reset: boolean = false) {
    if (reset) {
      setLoading(true);
    }
    const newPage = reset ? 0 : page;
    const params = {
      ...filters,
      limit: limit,
      offset: newPage * limit,
    }
    source[0] = axios.CancelToken.source();
    getTaxes(source[0], params)
      .then((response: any) => {
        setHasMore(!!response.next);
        setPage(prev => reset ? 1 : prev + 1);
        setCount(response.count);
        setTaxes(prev => reset ? response.results : prev.concat(response.results));
        setLoading(false);
      })
      .catch(error => {
        if (!error.isCanceled) {
          setHasMore(false);
          setPage(0);
          setCount(0);
          setTaxes([]);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    source[1] = axios.CancelToken.source();
    return () => {
      source.forEach(s => s.cancel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters && filters.id === new TaxFilters().id) {
      fetchTaxes(true);
    }
    return () => {
      source[0].cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // const handleOpenModal = () => {
  //   dispatch(showDialog());
  // };

  const handleClose = () => {
    dispatch(dismissDialog());
    dispatch(dismissDialogEdit());
  };

  const handleOptions = (option: 'edit', tax: any, event: any) => {
    event.stopPropagation();
    switch (option) {
      case 'edit':
        setSelectedTax(tax);
        dispatch(showDialogEdit());
        break;
    }
  };

  return (
    <React.Fragment>
      {filters && <FancyFilter defaultValues={new TaxFilters()} />}
      {
        !loading && <FancyList
          infinite
          elements={taxes}
          count={count}
          hasMore={hasMore}
          fetchElements={fetchTaxes}
          ListItemTextProps={(tax: any) => ({
            primary: `${tax.name}`,
            secondary: `${tax.percentage}%`
          })}
          actionButtons={actionButtons}
        />
      }
      {loading && <FancyProgress color="primary" size={100} />}
      {/* <Fab color="primary" aria-label="add" className={classes.button} onClick={handleOpenModal}>
        <AddIcon />
      </Fab> */}
      <FancyModal fullWidth maxWidth="xs" open={dialog.open || dialog.openEdit} onClose={handleClose} >
        <DialogContent>
          <TaxFormComponent
            fetchTaxes={() => fetchTaxes(true)}
            taxId={dialog.openEdit ? selectedTax?.id : null}
          />
        </DialogContent>
      </FancyModal>
    </React.Fragment>
  );
}
