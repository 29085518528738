import React, { useState } from 'react'
// Material ui
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// App
import { FancyButton, PasswordTextInput } from '../utils/FancyComponents'
import Logo from '../assets/media/tke.svg';
// Form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";
// Redux
import { useDispatch } from 'react-redux';
// Services
import { newPassword } from '../services/AuthServices';
// Others
import { Link, useHistory, useLocation } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: 60,
      width: 'auto'
    },
    span: {
      textAlign: 'center',
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      }
    },
    paper: {
      width: '350px',
      height: '100%',
      padding: theme.spacing(2, 3),
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        padding: theme.spacing(1, 2),
      }
    }
  }),
);

const schema = yup.object().shape({
  password: yup.string().required().min(6),
  passwordConfirm: yup.string().oneOf([yup.ref('password')], 'La confirmación de la contraseña no coincide'),
});

let source: CancelTokenSource = axios.CancelToken.source();

export default function RestorePasswordForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const styles = useStyles();
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const { handleSubmit, control, errors } = methods;
  const [busy, setBusy] = useState(false);

  React.useEffect(() => {
    source = axios.CancelToken.source();
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: any) => {
    setBusy(true);
    const queryparams = new URLSearchParams(location.search);
    if (queryparams.has('pin')) {
      data['code'] = queryparams.get('pin');
    }
    if (queryparams.has('email')) {
      data['email'] = queryparams.get('email');
    }
    newPassword(data, history, dispatch, source)
      .catch(error => {
        if (!error.isCanceled) {
          setBusy(false);
        }
      });
  }

  return (
    <Paper className={styles.paper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} container justify="center">
            <img src={Logo} alt="logo" className={styles.logo} />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h6">Cambiar Contraseña</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={PasswordTextInput}
              control={control}
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              defaultValue=""
              name="password"
              label="Nueva Contraseña"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={PasswordTextInput}
              control={control}
              error={!!errors.passwordConfirm}
              helperText={errors.passwordConfirm && errors.passwordConfirm.message}
              defaultValue=""
              name="passwordConfirm"
              label="Confirmación de Contraseña"
            />
          </Grid>
          <Grid item xs={12} container direction="row" justify="center">
            <FancyButton
              variant="contained"
              color='primary'
              type="submit"
              disabled={busy}
              loading={busy}
            >
              Recuperar contraseña
            </FancyButton>
            <Grid item xs={12} container direction="row" justify="center">
              <Typography component={Link} to='/' className={styles.span}>
                Volver al inicio de sesión
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}
