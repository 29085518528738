import { SET_REQUEST_EVENT_DATA, CLEAR_REQUEST_EVENT_DATA, RequestEvent } from "../types/requestEventsTypes";

const initialState: RequestEvent = new RequestEvent();

export default function requestEventsReducer(state = initialState, action: any): RequestEvent {
  switch (action.type) {
    case SET_REQUEST_EVENT_DATA:
      return {
        ...action.payload,
      };
    case CLEAR_REQUEST_EVENT_DATA:
      return initialState;
    default:
      return state;
  }
}