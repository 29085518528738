import React, { useEffect } from 'react';
// Material UI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// Forms
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
// App
import { FancyTextInput, useFancyStyles } from '../../../utils/FancyComponents';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { buttonSaveDisable } from '../../../redux/actions/ButtonActions';
// Services
import { updateUserProfile } from '../../../services/AuthServices';
// Others
import axios, { CancelTokenSource } from 'axios';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
});

let source: CancelTokenSource = axios.CancelToken.source();

export default function GeneralFormComponent() {
  const dispatch = useDispatch();
  const styles = useFancyStyles();
  const user = useSelector((state: RootState) => state.auth.user);
  const saveButton = useSelector((state: RootState) => state.saveButton.buttonSave);
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
    }
  });
  const { handleSubmit, control, errors, reset } = methods;

  useEffect(() => {
    source = axios.CancelToken.source();
    reset({
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      email: user.email,
    });

    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (saveButton) {
      handleSubmit(onSubmit, (errors) => dispatch(buttonSaveDisable()))();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveButton]);

  const onSubmit = (data: any) => {
    data['username'] = data.email;
    updateUserProfile(user.id, data, dispatch, source)
      .then(response => {
        dispatch(buttonSaveDisable());
      })
      .catch(error => {
        if (!error.isCanceled) {
          dispatch(buttonSaveDisable());
        }
      });
  }

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12} md={user.is_company ? 7 : 12} lg={user.is_company ? 8 : 12}>
        <Paper variant="outlined" className={styles.p_2}>
          <Grid container alignContent="flex-start" spacing={1}>
            <Grid item xs={12} sm={6}>
              <Controller
                as={FancyTextInput}
                control={control}
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
                name="email"
                type="email"
                label="Email"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                as={FancyTextInput}
                control={control}
                error={!!errors.first_name}
                helperText={errors.first_name && errors.first_name.message}
                name="first_name"
                label="Nombre"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                as={FancyTextInput}
                error={!!errors.last_name}
                helperText={errors.last_name && errors.last_name.message}
                control={control}
                name="last_name"
                label="Apellido"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}